/* eslint-disable no-unused-expressions */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';
import { useHandleGetWithPostMutation } from './app/features/api/CommonGetWithPostApi';
import { Logout } from './app/features/AuthSlice';
import { PLATFORM_PROVIDER } from './Constant';
import CommonChatBot from './components/Common/CommonChatBot';
import ErrorPage from './Layout/RootLayout/ErrorPage';

const App = () => {
    const dispatch = useDispatch();
    const { networkError } = useSelector((store) => store.err);
    const { loginnedToken, userType, isAuthenticated } = useSelector((store) => store.auth);
    const [timeout, setTimeout] = useState(300000);
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    const handleOnIdle = () => {
        if (loginnedToken !== null) {
            dispatch(Logout());
        }
    };
    const globalTimeout = (async () => {
        if (loginnedToken !== null) {
            const response = await handleGetWithPost({ url: '/globals/configurations', data: { slug: 'logout_timeout' } });
            if (response?.data?.status === 200) {
                const timeOut = parseInt(response?.data?.data[0]?.configuration_data?.logout_timeout, 10) * 60000;
                setTimeout(timeOut);
            }
        }
    });
    useEffect(() => {
        globalTimeout();
    }, [loginnedToken]);
    const idleTimer = useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        events: ['mousemove', 'keydown', 'wheel'],
    });
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            idleTimer.pause();
        } else {
            idleTimer.resume();
        }
    });
    if (networkError) return <ErrorPage />;
    return (
        <>
            <RouterProvider router={routes} future={{ v7_startTransition: true }} />
            <CommonChatBot isAuthenticated={isAuthenticated && userType !== PLATFORM_PROVIDER} />
        </>
    );
};
export default App;
