import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ADVERTISER } from '../Constant';
import { useHandlePostMutation } from '../app/features/api/CommonPostApi';
import { Toast } from '../components/Common/Toaster';

const MaskLogin = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [handlePost] = useHandlePostMutation();
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await handlePost({ url: 'platformadmin/advertisers/login', data: { token } }).unwrap();
                if (res?.status === 200) {
                    sessionStorage.setItem('user_type', ADVERTISER);
                    sessionStorage.setItem('token', res.data?.token);
                    window.location.href = '/dashboard';
                }
            } catch (error) {
                if (error) {
                    Toast({ type: 'error', message: error?.data?.message });
                    navigate('/login');
                }
            }
        }
        fetchData();
    }, []);
    return <h5>Redirecting..</h5>;
};
export default MaskLogin;
