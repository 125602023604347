/* eslint-disable no-unused-expressions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable valid-typeof */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import BasicTable from '../../components/Tables/BasicTable';
import withGetContainer from '../../Container/withGetContainer';
import PaginationComponent from '../../components/Common/Pagination';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { addPageLoadActivityLog, getModulePermission } from '../../Util';
import { MAX_LENGTH, PLATFORM_PROVIDER } from '../../Constant';
import ExportCSVPopup from './ExportCSVPopup';
import IsMoreDataPopup from './IsMoreDataPopup';
import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import Loader from '../loader';

const ActivityLogList = ({
    data, isLoading, isFetching, refetchRecord,
}) => {
    getModulePermission('view', 'page');
    const dispatch = useDispatch();
    const { searchParams } = useSelector((store) => store.common);
    const [callSummaryPopup, setCallSummaryPopup] = useState(false);
    const [isMoreData, setIsMoreData] = useState(false);
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const date = new Date();
    const [exportLoader, setExportLoader] = useState(false);
    const [datefilter, setDateFilter] = useState({ startDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: date });
    const datechange = (dateObject) => {
        if (!dateObject) {
            return '';
        }
        const year = dateObject.getFullYear();
        const month = `0${dateObject.getMonth() + 1}`.slice(-2);
        const day = `0${dateObject.getDate()}`.slice(-2);

        const withHyphens = [year, month, day].join('-');
        return withHyphens;
    };
    const startDate = datechange(datefilter.startDate);
    const endDate = datechange(datefilter.endDate);
    const refetchRecordAccess = () => {
        dispatch(handleSearchParams({ ...searchParams, page: 1 }));
        setPage(1);
        refetchRecord();
    };
    const columns = [
        {
            Header: 'NAME',
            accessor: (originalRow) => (
                <p title={originalRow?.username}>
                    {originalRow?.username ? (
                        originalRow?.username.length >= MAX_LENGTH
                            ? `${originalRow?.username?.substring(0, MAX_LENGTH)}...`
                            : originalRow?.username
                    ) : (
                        '-'
                    )}
                </p>
            ),
        },
        {
            Header: 'EMAIL ADDRESS',
            accessor: (originalRow) => (
                <p title={originalRow?.email}>
                    {originalRow?.email ? (
                        originalRow?.email.length >= 30
                            ? `${originalRow?.email?.substring(0, 30)}...`
                            : originalRow?.email
                    ) : (
                        '-'
                    )}
                </p>
            ),
        },
        {
            Header: 'IP ADDRESS',
            accessor: 'ipaddress',
        },
        {
            Header: 'DATE & TIME',
            accessor: (originalRow) => (
                <p title={originalRow?.created_at}>
                    {originalRow?.formattedDate ? (originalRow?.formattedDate.length >= 50 ? `${originalRow?.formattedDate?.substring(0, 50)}...` : originalRow?.formattedDate) : ''}
                </p>
            ),
        },
        {
            Header: 'MODULE NAME',
            accessor: (originalRow) => (
                <p title={originalRow?.module} className={'text-capitalize'}>
                    {originalRow?.module ? (
                        originalRow?.module.length >= MAX_LENGTH
                            ? `${originalRow?.module?.substring(0, MAX_LENGTH)}...`
                            : originalRow?.module
                    ) : (
                        '-'
                    )}
                </p>
            ),
        },
        {
            Header: 'ACTION',
            accessor: (row) => (
                <span className="text-capitalize" title={row.method}>{row.method}</span>
            ),
        },
    ].filter(Boolean);
    useEffect(() => {
        if (datefilter) {
            dispatch(handleSearchParams({
                page: 1, keyword: '', startDate, endDate,
            }));
        } else {
            dispatch(handleSearchParams({ page: 1, keyword: '' }));
        }
        window.scrollTo(0, 0);
        addPageLoadActivityLog('Activity Log');
    }, []);
    const clearedCalendar = () => {
        dispatch(handleSearchParams({
            page: 1, keyword: '',
        }));
    };
    useEffect(() => {
        const closeIcon = document.querySelector('.react-datepicker__close-icon');
        if (closeIcon) {
            closeIcon.addEventListener('click', clearedCalendar);
        }
        return () => {
            if (closeIcon) {
                closeIcon.removeEventListener('click', clearedCalendar);
            }
        };
    }, []);
    const handlePage = (value) => {
        setPage(value);
        dispatch(handleSearchParams({ ...searchParams, page: value }));
    };
    const shouldExportCSV = () => {
        setCallSummaryPopup(true);
    };
    const openPopup = () => {
        setIsMoreData(true);
    };

    const handleExportCsv = async () => {
        try {
            setExportLoader(true);
            const values = {
                startDate: datechange(datefilter.startDate),
                endDate: datechange(datefilter.endDate),
                page: count,
                keyword: searchParams.keyword,
                advertiser_id: searchParams.advertiser_id,
            };

            const res = await handleGetWithPost({ url: 'activity_logs/export_csv', data: values });

            if (res.data.status === 200) {
                setExportLoader(false);
                if (res.data.data.more_data) {
                    setIsMoreData(true);
                    setCount((prevCount) => prevCount + 1);
                } else {
                    setIsMoreData(false);
                }

                const downloadURL = res.data.data.url;
                const a = document.createElement('a');
                a.href = downloadURL;
                a.download = 'exported_data.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setCallSummaryPopup(false);
            } else {
                setExportLoader(false);
            }
        // eslint-disable-next-line no-empty
        } catch (error) {
        }
    };
    const handleClose = () => {
        setCallSummaryPopup(false);
        setIsMoreData(false);
        setCount(0);
    };
    useEffect(() => {
        if (isMoreData) {
            openPopup();
        }
    }, [isMoreData]);
    return (
        <div>
            {exportLoader && <Loader />}
            <ExportCSVPopup
                show={callSummaryPopup}
                handleClose={handleClose}
                handleSubmit={handleExportCsv}
            />
            {isMoreData && (
                <IsMoreDataPopup
                    show={isMoreData}
                    handleClose={handleClose}
                    handleSubmit={handleExportCsv}
                />
            )}
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Activity Log"
                    Breadcrumb={[
                        { name: 'Activity Log', navigate: '' },
                    ]}
                />
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card border-0 borderRadius-10 plan-data-show">
                            <BasicTable
                                data={data?.data || []}
                                columns={columns}
                                title="Activity Log"
                                isFetching={isFetching}
                                isLoading={isLoading}
                                handleExportCsv={shouldExportCSV}
                                ExportCsv
                                showCalander
                                columnFilter={false}
                                datepickerRange={datefilter}
                                datechangeFilter={setDateFilter}
                                isRefetch
                                handleRefetchRecord={refetchRecordAccess}
                            />
                        </div>
                        <PaginationComponent
                            itemsCount={data.pagination?.total || 1}
                            itemsPerPage={10}
                            currentPage={page}
                            dataLength={data?.data?.length}
                            setCurrentPage={handlePage}
                            alwaysShown={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
ActivityLogList.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    refetchRecord: PropTypes.func.isRequired,
};
ActivityLogList.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: ActivityLogList, url: 'activity_logs/?items_per_page=10', module: '' });
