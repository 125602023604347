export const useAsyncLocalStorage = {
    setItem: (key, value) => Promise.resolve().then(() => sessionStorage.setItem(key, value)),
    getItem: (key) => Promise.resolve().then(() => sessionStorage.getItem(key)),
    remoteItem: (key) => Promise.resolve().then(() => sessionStorage.removeItem(key)),
};

export const getLocalStorageData = async (key) => new Promise((resolve) => {
    resolve(sessionStorage.getItem(key));
});

export const setLocalStorageData = async (key, value) => new Promise((resolve) => {
    resolve(sessionStorage.setItem(key, value));
});
