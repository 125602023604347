import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { baseAPIService } from './features/api';
import AuthReducer from './features/AuthSlice';
import CommonReducer from './features/CommonSlice';
import NavigationReducer from './features/NavigationSlice';
import ErrorReducer from './features/ErrorSlice';

const store = configureStore({
    reducer: {
        auth: AuthReducer,
        navigation: NavigationReducer,
        common: CommonReducer,
        err: ErrorReducer,
        [baseAPIService.reducerPath]: baseAPIService.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseAPIService.middleware),
    devTools: true,
});

setupListeners(store.dispatch);

export default store;
