/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useHandleUpdateOrDeleteMutation } from '../../app/features/api/CommonChangeApi';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
import { useHandlePostMutation } from '../../app/features/api/CommonPostApi';
import { Toast } from '../../components/Common/Toaster';

const withPostContainer = (WrappedComponent, url, isProfile) => {
    const newComponent = () => {
        const navigate = useNavigate();
        const [handlePost] = useHandlePostMutation();
        const { permissions } = useSelector((store) => store.auth);
        const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();
        const { id } = useParams();
        const { data, error, isLoading: dataLoading } = useHandleGetApiQuery(
            { url: `${url}${id}` },
            { skip: !(id && url), refetchOnMountOrArgChange: true },
            id,
        );
        if (error?.status === 404 || error?.status === 400) {
            navigate('/dashboard');
        }
        useEffect(() => {
            if ((!permissions?.create && isProfile === false) || (!permissions?.update && isProfile === false)) {
                navigate(-1);
            }
        }, [permissions]);
        const handlePostRequest = async ({
            url: urls, data: value, isUpdate, redirect, setSubmitting, formik, onTabChange,
        }) => {
            if (setSubmitting) {
                setSubmitting(true);
            }
            try {
                const response = isUpdate
                    ? await handleUpdateOrDelete({
                        url: `${urls}`,
                        data: value,
                        method: 'PUT',
                    })
                    : await handlePost({ url: urls, data: value });
                if (response?.data?.status === 201 || response?.data?.status === 200) {
                    if (setSubmitting) {
                        setSubmitting(false);
                    }
                    if (redirect) {
                        Toast({ message: response?.data.message });
                        navigate(redirect);
                    }
                } else {
                    if (setSubmitting) {
                        setSubmitting(false);
                    }
                    const statusCode = response.error.status;
                    if (statusCode === 422) {
                        const errors = response.error.data?.fields;
                        if (errors) {
                            Object.keys(errors).map((keyName) => formik.setFieldError(keyName, errors[keyName]));
                        }
                        const errorsArr = response.error.data?.errors;
                        if (errorsArr) {
                            Object.keys(errorsArr).map((keyName) => formik.setFieldError(keyName, errorsArr[keyName]));
                        }
                        const errorMessage = response.error.data.errors.name;
                        if (errorMessage) {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            if (onTabChange) {
                                onTabChange(1);
                            }
                        }
                    }
                }
            // eslint-disable-next-line no-empty
            } catch (err) {
                if (setSubmitting) {
                    setSubmitting(false);
                }
            }
        };
        return (
            <WrappedComponent handlePostRequest={handlePostRequest} data={data} id={id} dataLoading={dataLoading} />
        );
    };
    return newComponent;
};
export default withPostContainer;
