import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    getLocalStorageData,
    setLocalStorageData,
} from '../../../hooks/useLocalStorage';
import { Logout, setCredentials } from '../AuthSlice';
import { setNetworkError } from '../ErrorSlice';

// Custom serializer function to convert array to string
// const arraySerializer = (value) => {
//     if (Array.isArray(value)) {
//         return `[${value.join(', ')}]`;
//     }
//     return value;
// };

const arraySerializer = (value) => {
    if (Array.isArray(value)) {
        return `[${value.map((el) => {
            if (typeof el === 'number') {
                return +el;
            } if (typeof el === 'string') {
                return `"${el}"`;
            }
            return el;
        }).join(', ')}]`;
    }
    return value;
};

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    paramsSerializer: (params) => {
        const newParamString = Object.entries(params)
            .map(([key, value]) => `${key}=${arraySerializer(value)}`)
            .join('&');
        return newParamString;
    },
    prepareHeaders: async (headers) => {
        const token = await getLocalStorageData('token');
        if (token) {
            headers.set('token', `${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    try {
        let result = await baseQuery(args, api, extraOptions);
        if (result?.error?.status === 401) {
            const remember = JSON.parse(sessionStorage.getItem('isRemember'));
            if (remember) {
                const getRefreshToken = await getLocalStorageData('refresh_token');
                if (getRefreshToken) {
                    sessionStorage.setItem('token', getRefreshToken);
                    const refreshResult = await baseQuery({ url: '/platformadmin/token/', method: 'POST', body: { refresh_token: getRefreshToken } }, api, extraOptions);
                    if (refreshResult?.error) {
                        api.dispatch(Logout());
                    }
                    if (refreshResult?.data.status === 200) {
                        sessionStorage.setItem('token', refreshResult?.data?.data?.token);
                        await setLocalStorageData('token', refreshResult?.data?.data?.token);
                        api.dispatch(setCredentials({ token: refreshResult?.data?.data?.token, isAuthenticated: true }));
                        result = await baseQuery(args, api, extraOptions);
                    }
                }
            } else {
                api.dispatch(Logout());
            }
        }
        return result;
    } catch {
        if (!navigator.onLine) {
            api.dispatch(setNetworkError(true));
            return { error: { status: 'NETWORK_ERROR', message: 'No internet connection' } };
        }
        return { error: { status: 'UNKNOWN_ERROR', message: 'An unknown error occurred' } };
    }
};

export const baseAPIService = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['COMMON_GET'],
    endpoints: () => ({}),
});
