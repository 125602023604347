import { baseAPIService } from '.';

const CommonPutApi = baseAPIService.injectEndpoints({
    endpoints: (build) => ({
        handlePut: build.mutation({
            query: ({ url, data }) => ({
                url,
                method: 'PUT',
                body: data,
            }),
        }),
    }),
    overrideExisting: true,
});

// eslint-disable-next-line import/prefer-default-export
export const { useHandlePutMutation } = CommonPutApi;
