/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InputWithTooltip = ({
    value, label, name, onChange, placeholder, type, text, required, ...rest
}) => (
    <>
        <label htmlFor={name}>
            {label}
            {required && <span className="text-red">*</span>}
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={(
                    <Tooltip id="tooltip-top">
                        {text}
                    </Tooltip>
                )}
            >
                <i className="icon-question ml-1 align-middle" />

            </OverlayTrigger>
        </label>
        <div className="form-group mb-3">
            <input
                className="form-control"
                placeholder={placeholder}
                type={type}
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                {...rest}
            />
        </div>
    </>
);

InputWithTooltip.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    required: PropTypes.bool,
};

InputWithTooltip.defaultProps = {
    placeholder: '',
    type: 'text',
    required: false,
};

export default InputWithTooltip;
