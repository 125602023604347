/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes, { number } from 'prop-types';
import { useTable } from 'react-table';
import Loader from '../../screens/loader';
// import Loader from '../../screens/loader';
import PaginationComponent from './Pagination';

const FilterTable = ({
    individualColumns, individualData, dataLoader, doNotScroll, showPagination, handleFilterTablePage, currentFilterTablePage,
}) => {
    const tableInstance = useTable({
        columns: individualColumns,
        data: individualData?.data || [],
    });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;
    const { role: tableRole, 'aria-labelledby': ariaLabelledby, style: tableStyle } = getTableProps();
    const { role: bodyRole, style: bodyStyle } = getTableBodyProps();

    return dataLoader ? (<Loader doNotScroll={doNotScroll} />) : (
        <>
            {individualData && (
                <div className="body table-responsive child-table p-3">
                    <table className="table table-hover" role={tableRole} aria-labelledby={ariaLabelledby} style={tableStyle}>
                        <thead>
                            {headerGroups.map((headerGroup) => {
                                const { key: headerGroupKey, role: headerGroupRole, style: headerGroupStyle } = headerGroup.getHeaderGroupProps();
                                return (
                                    <tr key={headerGroupKey} role={headerGroupRole} style={headerGroupStyle}>
                                        {headerGroup.headers.map((column) => {
                                            const { key: headerKey, role: headerRole, style: headerStyle } = column.getHeaderProps();
                                            return (
                                                <th key={headerKey} role={headerRole} style={headerStyle}>
                                                    {column.render('Header')}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody role={bodyRole} style={bodyStyle}>
                            {rows.length > 0 ? rows.map((row) => {
                                prepareRow(row);
                                const { key: rowKey, role: rowRole, style: rowStyle } = row.getRowProps();
                                return (
                                    <tr key={rowKey} role={rowRole} style={rowStyle}>
                                        {row.cells.map((cell) => {
                                            const { key: cellKey, role: cellRole, style: cellStyle } = cell.getCellProps();
                                            return (
                                                <td key={cellKey} role={cellRole} style={cellStyle}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }) : (
                                <tr>
                                    <td className="text-center" colSpan={100}><p>No data available.</p></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            { (showPagination && individualData?.pagination) && (
                <PaginationComponent
                    itemsCount={individualData?.pagination?.total || 1}
                    itemsPerPage={10}
                    setCurrentPage={handleFilterTablePage}
                    currentPage={currentFilterTablePage}
                    dataLength={individualData?.data?.length}
                    alwaysShown={false}
                />
            )}
        </>
    );
};

FilterTable.propTypes = {
    individualColumns: PropTypes.arrayOf(PropTypes.shape({
        Header: PropTypes.string.isRequired,
        accessor: PropTypes.func.isRequired,
    })),
    individualData: PropTypes.instanceOf(Array),
    dataLoader: PropTypes.bool,
    doNotScroll: PropTypes.bool,
    showPagination: PropTypes.bool,
    handleFilterTablePage: PropTypes.func,
    currentFilterTablePage: PropTypes.number,
};

FilterTable.defaultProps = {
    individualColumns: [],
    individualData: [],
    dataLoader: false,
    doNotScroll: false,
    showPagination: true,
    handleFilterTablePage: PropTypes.func,
    currentFilterTablePage: number,
};

export default FilterTable;
