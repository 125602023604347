import { React, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageNotFoundError from '../../screens/404';
import NoInternetConnection from '../../screens/noInternetConnection';
import ChunkErrorPage from '../../screens/chunk-error-page';

const ErrorPage = () => {
    const { networkError } = useSelector((store) => store.err);
    const [chunkError, setChunkError] = useState(false);
    let error = '';
    if (!networkError) {
        error = useRouteError();
        if (typeof error?.status === 'undefined') {
            setChunkError(true);
        }
    }
    return (
        <h1>
            {networkError && <NoInternetConnection /> }
            {error?.status === 404 && <PageNotFoundError />}
            {chunkError && <ChunkErrorPage /> }
        </h1>
    );
};
export default ErrorPage;
