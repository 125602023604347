import React, { useEffect, useState } from 'react';
import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import PageHeader from '../../components/PageHeader';
import creatTicket from '../../assets/images/helpdesk/create-ticket.svg';
import bookMeeting from '../../assets/images/helpdesk/book-meeting.svg';
import portNumber from '../../assets/images/helpdesk/port-number.svg';

const HelpDesk = () => {
    const [ticketUrl, setTicketUrl] = useState('');
    const [meetingUrl, setMeetingUrl] = useState('');
    const [portingUrl, setPortingUrl] = useState('');
    const [handleGetWithPost] = useHandleGetWithPostMutation();

    const fetchUrl = async () => {
        const response = await handleGetWithPost({ url: '/globals/configurations', data: { slug: 'number_porting_url' } });
        if (response?.data?.status === 200) {
            const urlTicket = response?.data?.data[0]?.configuration_data?.number_porting_url?.CREATE_TICKET;
            setTicketUrl(urlTicket);
            const urlMeeting = response?.data?.data[0]?.configuration_data?.number_porting_url?.BOOK_MEETING;
            setMeetingUrl(urlMeeting);
            const urlPorting = response?.data?.data[0]?.configuration_data?.number_porting_url?.PORT_YOUR_NUMBER;
            setPortingUrl(urlPorting);
        }
    };
    useEffect(() => {
        fetchUrl();
    }, []);
    const handleClick = (action) => () => {
        if (action === 'ticket') {
            if (ticketUrl) {
                window.open(ticketUrl, '_blank', 'noopener,noreferrer');
            }
        } else if (action === 'meeting') {
            if (meetingUrl) {
                window.open(meetingUrl, '_blank', 'noopener,noreferrer');
            }
        } else if (action === 'porting') {
            if (portingUrl) {
                window.open(portingUrl, '_blank', 'noopener,noreferrer');
            }
        }
    };
    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Help"
                    Breadcrumb={[
                        { name: 'Help', navigate: '/help' },
                    ]}
                />
                <div className="container-fluid mt-5 box-section">
                    <div className="row justify-content-center">
                        {/* CREATE TICKET Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div className="card first-box">
                                <div className="card-body p-0">
                                    <img className="d-block m-auto" src={creatTicket} alt="Icon" />
                                    <h5>CREATE TICKET</h5>
                                    <ul className="mt-3">
                                        <li>Sales/Upgrade</li>
                                        <li>Technical Support</li>
                                        <li>Billing Support</li>
                                        <li>Legal</li>
                                    </ul>
                                    <button type="button" onClick={handleClick('ticket')} className="btn">CREATE TICKET</button>
                                </div>
                            </div>
                        </div>

                        {/* BOOK MEETING Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div className="card second-box">
                                <div className="card-body p-0">
                                    <img className="d-block m-auto" src={bookMeeting} alt="Icon" />
                                    <h5>BOOK MEETING</h5>
                                    <ul className="mt-3 pb-5">
                                        <li>Product Demo/Features</li>
                                        <li>Technical Support</li>
                                        <li>Billing Support</li>
                                        <li>Legal</li>
                                    </ul>
                                    <button type="button" onClick={handleClick('meeting')} className="btn">BOOK MEETING</button>
                                </div>
                            </div>
                        </div>

                        {/* PORT YOUR NUMBER Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div className="card third-box">
                                <div className="card-body p-0">
                                    <img className="d-block m-auto" src={portNumber} alt="Icon" />
                                    <h5>PORT YOUR NUMBER</h5>
                                    <p className="card-text note">Port your existing Phone Number from your existing provider to ATLAS.</p>
                                    <button type="button" onClick={handleClick('porting')} className="btn mt-4">PORT YOUR NUMBER</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HelpDesk;
