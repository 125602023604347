/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes, { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import BasicTable from '../../../components/Tables/BasicTable';
import withGetContainer from '../../../Container/withGetContainer';
import PaginationComponent from '../../../components/Common/Pagination';
import { handleSearchParams } from '../../../app/features/CommonSlice';
import { MAX_LENGTH } from '../../../Constant';
import PaymentStatusChangePopup from './PaymentStatusChangePopup';
import { Toast } from '../../../components/Common/Toaster';
import { useHandleUpdateOrDeleteMutation } from '../../../app/features/api/CommonChangeApi';
import { addPageLoadActivityLog, getModulePermission } from '../../../Util';

const PaymentMethodList = ({
    data, isLoading, isFetching,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    getModulePermission('view', 'page');
    const updatePermission = getModulePermission('update', 'button');
    const deletePermission = getModulePermission('delete', 'button');

    const [toggleStatusModal, setToggleStatusModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState({});
    const { searchParams } = useSelector((store) => store.common);
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();
    const handleStatusModal = useCallback((values) => async () => {
        setSelectedPayment(values);
        setToggleStatusModal(true);
    }, []);
    const handleEdit = useCallback((value) => () => {
        navigate(`edit-method/${value.id}`);
    }, []);
    const columns = [
        {
            id: 'logo',
            accessor: (row) => (
                <img src={row.logo} alt="payment-icon" width="40" />
            ),
        },
        {
            Header: 'PAYMENT GATEWAY NAME',
            id: 'name',
            accessor: (row) => {
                const name = row?.name;
                return (
                    <p title={name} className="mb-0">
                        {name ? (name.length >= MAX_LENGTH ? `${name?.substring(0, MAX_LENGTH)}...` : name) : ''}
                    </p>
                );
            },
        },
        {
            Header: 'STATUS(ACTIVE/INACTIVE)',
            accessor: (row) => (
                <div id="slider" className="switch">
                    <label>
                        <input
                            type="checkbox"
                            id={row?.id}
                            checked={parseInt(row?.status, 10)}
                            name="status"
                            onChange={handleStatusModal(row)}
                        />
                        <span className="slider round" />
                    </label>
                </div>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: (originalRow) => (
                <div className="d-flex align-items-center gap-2 action-lists">
                    { updatePermission && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={handleEdit(originalRow)}>
                            <title>Edit</title>
                            <path d="M16.2114 8.67142C15.8878 8.67142 15.6254 8.93377 15.6254 9.25737V17.8514C15.6254 18.3899 15.1873 18.828 14.6488 18.828H2.1485C1.61001 18.828 1.17191 18.3899 1.17191 17.8514V5.35101C1.17191 4.81252 1.61001 4.37442 2.1485 4.37442H10.7425C11.0661 4.37442 11.3284 4.11207 11.3284 3.78847C11.3284 3.46487 11.0661 3.20251 10.7425 3.20251H2.1485C0.963816 3.20251 0 4.16633 0 5.35101V17.8514C0 19.036 0.963816 19.9999 2.1485 19.9999H14.6488C15.8335 19.9999 16.7973 19.036 16.7973 17.8514V9.25737C16.7973 8.93377 16.535 8.67142 16.2114 8.67142Z" />
                            <path d="M19.6002 1.50465L18.4952 0.399735C17.9622 -0.133327 17.0948 -0.133327 16.5617 0.399735L7.72267 9.23881C7.64087 9.32061 7.58513 9.42479 7.56239 9.53824L7.00991 12.3005C6.97151 12.4926 7.03163 12.6912 7.17019 12.8297C7.28117 12.9407 7.43067 13.0013 7.5845 13.0013C7.62274 13.0013 7.66118 12.9976 7.69939 12.99L10.4616 12.4375C10.5751 12.4148 10.6792 12.359 10.761 12.2772L19.6002 3.43818C19.6002 3.43818 19.6002 3.43818 19.6002 3.43814C20.1332 2.90512 20.1332 2.03775 19.6002 1.50465ZM10.0579 11.3231L8.33147 11.6685L8.6768 9.94207L15.8711 2.74761L17.2523 4.12875L10.0579 11.3231ZM18.7715 2.60952L18.0809 3.30009L16.6998 1.91896L17.3903 1.22843C17.4665 1.15226 17.5904 1.15222 17.6666 1.22839L18.7715 2.33331C18.8477 2.40944 18.8477 2.53339 18.7715 2.60952Z" />
                        </svg>
                    )}
                </div>
            ),
            id: 'ACTION',
        },
    ];

    useEffect(() => {
        dispatch(handleSearchParams({ page: 1, keyword: '' }));
        addPageLoadActivityLog('Payment Method');
    }, []);
    const [page, setPage] = useState(1);
    const handlePage = (value) => {
        setPage(value);
        const checkKeyWordExist = searchParams;
        const filterArr = { page: value };
        if (checkKeyWordExist?.status !== undefined && checkKeyWordExist?.keyword !== undefined) {
            filterArr.keyword = checkKeyWordExist?.keyword;
            filterArr.status = checkKeyWordExist?.status;
        } else if (checkKeyWordExist?.keyword !== undefined) {
            filterArr.keyword = checkKeyWordExist?.keyword;
        } else if (checkKeyWordExist?.status !== undefined) {
            filterArr.status = checkKeyWordExist?.status;
        }
        if (filterArr) {
            dispatch(handleSearchParams(filterArr));
        }
    };
    const handlePlanStatus = async () => {
        const responseData = await handleUpdateOrDelete({ url: `gateways/status/${selectedPayment.id}`, method: 'PUT', data: { status: selectedPayment.status === '1' ? '0' : '1', slug: selectedPayment?.slug } });
        if (responseData?.data?.status === 200) {
            Toast({ type: 'success', message: responseData?.data?.message });
            setToggleStatusModal(false);
            window.scrollTo({ top: window.scrollY + window.scrollHeight, behavior: 'smooth' });
        } else {
            setToggleStatusModal(false);
        }
    };
    const handleClose = () => {
        setToggleStatusModal(false);
    };
    return (
        <div>
            <PaymentStatusChangePopup show={toggleStatusModal} handleClose={handleClose} handleSubmit={handlePlanStatus} selectedPaymentMethodInfo={selectedPayment} />

            <div className="container-fluid">
                <PageHeader
                    HeaderText="Payment"
                    Breadcrumb={[
                        { name: 'Payment', navigate: '' },
                        { name: 'Payment Method', navigate: '' },
                    ]}
                />
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card border-0 borderRadius-10 hidefilter">
                            <BasicTable
                                data={data?.data || []}
                                columns={columns}
                                title="Payment Method List"
                                isFetching={isFetching}
                                isLoading={isLoading}
                                deletePermission={deletePermission}
                                editPermission={updatePermission}
                                onlyUpdatePermission
                                columnFilter={false}
                            />
                        </div>
                        <PaginationComponent
                            itemsCount={data.pagination?.total || 1}
                            itemsPerPage={10}
                            currentPage={searchParams?.page || page}
                            dataLength={data?.data?.length}
                            setCurrentPage={handlePage}
                            // alwaysShown={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
PaymentMethodList.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
};
PaymentMethodList.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: PaymentMethodList, url: 'gateways/?items_per_page=10', module: 'wiretransfer' });
