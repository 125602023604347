import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNetworkError } from './app/features/ErrorSlice';

const NetworkError = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const checkClient = setInterval(() => {
            dispatch(setNetworkError(!window.navigator.onLine));
        }, 1000);
        return () => clearInterval(checkClient);
    }, [dispatch]);
    return null;
};

export default NetworkError;
