/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CommonModdal from '../../../components/Common/CommonModal';
import { fullName } from '../../../Util';

const DocumentPopup = ({
    show, handleClose, documentInfo,
}) => (
    <CommonModdal
        show={show}
        type="closeOpt"
        classAddon="theme-cyan KYC-approve-modal kyc-document-show-modal"
        size="lg"
        heading={`Attached document by ${fullName(documentInfo?.advertiser)} for amount ${documentInfo?.advertiser?.country?.currency_symbol} ${documentInfo.amount} `}
        handleClose={handleClose}
    >
        <div className="text-center">
            {documentInfo.content_type === 'application/pdf' ? (
                <iframe
                    title="show"
                    src={documentInfo.document}
                    id="frameId"
                    height={400}
                    width={700}
                />
            ) : (
                <img src={documentInfo.document} alt={documentInfo.name} width="500" />
            )}
        </div>
    </CommonModdal>
);
DocumentPopup.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    documentInfo: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};
export default DocumentPopup;
