/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import InputWithTooltip from '../../components/Common/InputWithTooltip';
import SearchableSelect from '../../components/Common/CommonSearchableSelect';
import withPostContainer from '../../Container/withPostContainer';
import { platformPlanValidationSchema } from '../../Validation';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import TooltipContainer from '../../components/Common/TooltipConatainer';
import { getModulePermission } from '../../Util';
import Loader from '../loader';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';

const AddPlan = ({
    handlePostRequest, id, data, dataLoading,
}) => {
    if (id) {
        getModulePermission('update', 'page');
    } else {
        getModulePermission('create', 'page');
    }
    const dispatch = useDispatch();
    const [serverData, setServerData] = useState();
    const [selectedCountry, setSelectedCountry] = useState('');
    const { data: countries, isLoading } = useHandleGetApiQuery({ url: 'countries?page=-1&number_of_records=10' });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const initialValues = {
        country_id: '',
        name: '',
        description: '',
        platform_charge: 0,
        plan_mode: 'monthly',
        local_number_rental_charge: 0,
        toll_free_number_rental_charge: 0,
        local_number_call_charge: 0,
        toll_free_number_call_charge: 0,
        recording_call_charge: 0,
        recording_storage_charge: 0,
        storage_limit: 0,
        enterprice_plan: false,
        priority: null,
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: platformPlanValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            delete values.updated_by;
            delete values.is_deleted;
            values.country_id = parseInt(values.country_id, 10);
            values.platform_charge = values.platform_charge ? parseFloat(values.platform_charge) : 0;
            values.local_number_call_charge = values.local_number_call_charge ? parseFloat(values.local_number_call_charge) : 0;
            values.local_number_rental_charge = values.local_number_rental_charge ? parseFloat(values.local_number_rental_charge) : 0;
            values.toll_free_number_call_charge = values.toll_free_number_call_charge ? parseFloat(values.toll_free_number_call_charge) : 0;
            values.toll_free_number_rental_charge = values.toll_free_number_rental_charge ? parseFloat(values.toll_free_number_rental_charge) : 0;
            values.recording_call_charge = values.recording_call_charge ? parseFloat(values.recording_call_charge) : 0;
            values.storage_limit = !values.storage_limit ? 0 : parseFloat(values.storage_limit);
            values.recording_storage_charge = !values.recording_storage_charge ? 0 : parseFloat(values.recording_storage_charge);
            const url = id ? `platformadmin/plan/${id}` : 'platformadmin/plan/';
            handlePostRequest({
                data: values,
                redirect: '/plans',
                isUpdate: !!id,
                url,
                setSubmitting,
                formik,
            });
        },
        enableReinitialize: true,
    });
    const getCountryInfo = (countryId) => {
        if (countries?.data) {
            const getCountryData = countries?.data?.filter((item) => parseInt(item.id, 10) === parseInt(countryId, 10));
            if (getCountryData) {
                setSelectedCountry(getCountryData?.[0]);
            }
        }
    };
    const handlePlanSubmit = () => {
        if (Object.keys(formik.errors).length > 0) {
            const firstError = document.querySelector('.formik-error');
            if (firstError) {
                (firstError?.parentElement ?? firstError)?.scrollIntoView();
                formik.handleSubmit();
            }
        } else {
            formik.handleSubmit();
        }
    };
    useEffect(() => {
        getCountryInfo(formik.values.country_id);
    }, [countries, formik.values.country_id]);
    const handleCountryChange = (val) => {
        formik.setFieldValue('country_id', val);
        getCountryInfo(val);
    };
    const handleEnterprisePlan = useCallback(async (e) => {
        formik.setFieldValue('enterprice_plan', !!e.target.checked);
    }, []);
    useEffect(() => {
        if (data) {
            setServerData({
                ...data?.data,
            });
        }
    }, [data]);

    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/plans');
    };
    return (
        <div className="container-fluid">
            {(dataLoading || isLoading) && <Loader />}
            <PageHeader
                HeaderText="Plan Management"
                Breadcrumb={[
                    { name: 'Plan Management', navigate: '/plans' },
                    { name: id ? 'Edit Plan Parameter' : 'Create Plan Parameter', navigate: '' },
                ]}
            />
            <div className="col-12 p-0 create-target route-add form-data">
                <div className="card themeTabs body">
                    <div className="card-tab-header">
                        <ul className="nav nav-tabs-new">
                            <li className="nav-item mr-1 active">
                                <div className="nav-link active">
                                    {id ? 'Edit Plan' : 'Add Plan'}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <form>
                            <div className="row">
                                <div className="col-md-6 col-12 ">
                                    <TooltipContainer label="Country" id="country_id" text="Select the country for which you want to create a plan parameter." required>
                                        <div className="input-group mb-3">
                                            <SearchableSelect label="Country" options={countries?.data} onChange={handleCountryChange} value={formik?.values?.country_id} />
                                        </div>
                                    </TooltipContainer>
                                    <ErrorMessageComponent error={formik.errors.country_id} touched={formik.touched.country_id} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <InputWithTooltip
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        label="Plan Name"
                                        text="Enter the name of the plan for which you're defining parameters."
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.name} touched={formik.touched.name} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <InputWithTooltip
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        label="Plan Description"
                                        text="Provide a description for the plan, explaining its purpose or features."
                                    />
                                    <ErrorMessageComponent error={formik.errors.description} touched={formik.touched.description} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <TooltipContainer label="Enterprise Plan" id="plan-status" text="If toggle is on then it is Enterprise Plan otherwise it is Global Plan." />
                                    <div>
                                        <label id="plan-switch" className="switch ms-0" htmlFor="plan-status">
                                            <input
                                                type="checkbox"
                                                id="plan-status"
                                                onChange={handleEnterprisePlan}
                                                checked={Boolean(formik.values.enterprice_plan)}
                                                name="plan-status"
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <InputWithTooltip
                                        name="priority"
                                        value={formik.values.priority || ''}
                                        onChange={formik.handleChange}
                                        label="Priority"
                                        type="number"
                                        text="Set the priority level to determine the order of plan items within your all plan"
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.priority} touched={formik.touched.priority} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-7">
                                    <TooltipContainer label="Plan Parameter" text="Define the specific parameter or setting for the plan in this country." />
                                    <div className="gray-box">
                                        <div className="table-responsive">
                                            <table className="table table-hover plan-parameter">
                                                <tr>
                                                    <th>PLAN PARAMETER</th>
                                                    <th>CHARGE</th>
                                                </tr>
                                                <tr>
                                                    <td>Plan Type</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <select className="form-control" name="plan_mode" value={formik.values.plan_mode} onChange={formik.handleChange}>
                                                                <option className="opacity5" value="">Select</option>
                                                                <option value="monthly">Monthly</option>
                                                                <option value="yearly">Yearly</option>
                                                            </select>
                                                        </div>
                                                        {formik.errors.plan_mode && (
                                                            <ErrorMessageComponent error={formik.errors.plan_mode} touched={formik.touched.plan_mode} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Platform fee</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.platform_charge}
                                                                onChange={formik.handleChange}
                                                                name="platform_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">
                                                                {' '}
                                                                {selectedCountry?.currency_symbol}
                                                            </span>
                                                            {formik.values.plan_mode === 'monthly' ? 'Per Month' : (formik.values.plan_mode === 'yearly' ? 'Per Year' : '')}
                                                        </div>
                                                        {formik.errors.platform_charge && (
                                                            <ErrorMessageComponent error={formik.errors.platform_charge} touched={formik.touched.platform_charge} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Local Numbers</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.local_number_rental_charge}
                                                                onChange={formik.handleChange}
                                                                name="local_number_rental_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            Per Month
                                                        </div>
                                                        {formik.errors.local_number_rental_charge && (
                                                            <ErrorMessageComponent error={formik.errors.local_number_rental_charge} touched={formik.touched.local_number_rental_charge} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Toll Free Numbers</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.toll_free_number_rental_charge}
                                                                onChange={formik.handleChange}
                                                                name="toll_free_number_rental_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            Per Month
                                                        </div>
                                                        {formik.errors.toll_free_number_rental_charge && (
                                                            <ErrorMessageComponent error={formik.errors.toll_free_number_rental_charge} touched={formik.touched.toll_free_number_rental_charge} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Local Call Charge</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.local_number_call_charge}
                                                                onChange={formik.handleChange}
                                                                name="local_number_call_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            Per Minute
                                                        </div>
                                                        { formik.errors.local_number_call_charge && (
                                                            <ErrorMessageComponent error={formik.errors.local_number_call_charge} touched={formik.touched.local_number_call_charge} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Toll Free Call Charge</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.toll_free_number_call_charge}
                                                                onChange={formik.handleChange}
                                                                name="toll_free_number_call_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            Per Minute
                                                        </div>
                                                        <ErrorMessageComponent error={formik.errors.toll_free_number_call_charge} touched={formik.touched.toll_free_number_call_charge} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Recording</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.recording_call_charge}
                                                                onChange={formik.handleChange}
                                                                name="recording_call_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            Per Minute
                                                        </div>
                                                        {formik.errors.recording_call_charge && (
                                                            <ErrorMessageComponent error={formik.errors.recording_call_charge} touched={formik.touched.recording_call_charge} />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Charge</td>
                                                    <td className="number-data-input">
                                                        <div className="d-flex align-items-center gap-2 position-relative">
                                                            <input
                                                                className="form-control"
                                                                value={formik.values.recording_storage_charge}
                                                                onChange={formik.handleChange}
                                                                name="recording_storage_charge"
                                                                type="number"
                                                            />
                                                            <span className="input-symbol">{selectedCountry?.currency_symbol}</span>
                                                            <input
                                                                className="form-control w-65px"
                                                                value={formik.values.storage_limit}
                                                                onChange={formik.handleChange}
                                                                name="storage_limit"
                                                                type="number"
                                                            />
                                                            GB Per Month
                                                        </div>
                                                        {formik.errors.recording_storage_charge && (
                                                            <ErrorMessageComponent error={formik.errors.recording_storage_charge} touched={formik.touched.recording_storage_charge} />
                                                        )}
                                                        {
                                                            formik.errors.storage_limit && (
                                                                <ErrorMessageComponent error={formik.errors.storage_limit} touched={formik.touched.storage_limit} />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pt-4">
                                    <button type="button" className="btn btn-primary mr-3 " onClick={handlePlanSubmit}>{` ${id ? 'Update' : 'Add'}`}</button>
                                    <button type="button" className="btn btn-secondary mr-3 " onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
AddPlan.propTypes = {
    handlePostRequest: PropTypes.func.isRequired,
    dataLoading: PropTypes.bool.isRequired,
};

export default withPostContainer(AddPlan, 'platformadmin/plan/');
