import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import errorImage from '../assets/images/code-error.png';
import Logo from '../assets/images/logo.png';

const Errorpage = () => (
    <div className="theme-cyan">
        <div className="page-not-found gap-3">
            <div className="image-section">
                <img alt="ErrorImage" src={errorImage} className="error-img" />
            </div>
            <div className="text-section text-justify">
                <img
                    src={Logo}
                    alt="Lucid Logo"
                    width="150"
                />
                <h1>404</h1>
                <h5>Sorry, the page not found.</h5>
                <p>The link you followed probably broken or the page has been removed.</p>
            </div>
        </div>
    </div>
);
export default Errorpage;
