import { baseAPIService } from '.';

const CommonPostApi = baseAPIService.injectEndpoints({
    endpoints: (build) => ({
        handlePost: build.mutation({
            query: ({ url, data, method }) => ({
                url,
                method: method || 'POST',
                body: data || null,
            }),
            invalidatesTags: ['COMMON_GET'],
        }),
    }),
    overrideExisting: true,
});
// eslint-disable-next-line import/prefer-default-export
export const { useHandlePostMutation } = CommonPostApi;
