/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable function-paren-newline */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable func-names */
/* eslint-disable camelcase */
import * as yup from 'yup';
import {
    BITCOIN_SLUG, RAZORPAY_SLUG, WIRETRANSFER_SLUG, WIRETRANSFER_USA_SLUG,
} from '../Constant';

const onlyAlphabet = /^[a-zA-Z]*$/;
const alphabetWithSpace = /^[a-zA-Z ]*$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const alphaNumericWithSpace = /^[a-z\d\_\s]+$/i;
// eslint-disable-next-line max-len
const urlValidate = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const emailRegex = /^((?=.{1,255}$)([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const maximumCharge = 99999999;
const decimalWith3Digit = /^[0-9]+(\.[0-9]{0,3})?$/;
const decimalWith2Digit = /^[0-9]+(\.[0-9]{0,2})?$/;
const Maximum5Digit = 99999;
const phoneRegExp = /^[0-9]{6,20}$/;
const highestDestinationPort = 65535;
const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
const IPaddressRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|:|:([0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4}|[0-9a-fA-F]{1,4}::([0-9a-fA-F]{1,4}:){0,5}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){2}(:[0-9a-fA-F]{1,4}){4}|([0-9a-fA-F]{1,4}:){3}(:[0-9a-fA-F]{1,4}){3}|([0-9a-fA-F]{1,4}:){4}(:[0-9a-fA-F]{1,4}){2}|([0-9a-fA-F]{1,4}:){5}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){7}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){5}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){4}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){3}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){2}:[0-9a-fA-F]{1,4}|[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4}:[0-9a-fA-F]{1,4})$/i;
const usernameRegex = /^[A-Za-z][A-Za-z0-9._]*$/;

export const passwordValidation = {
    password: yup
        .string()
        .required('You must enter a password.')
        .min(8, 'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.')
        .max(14, 'Password should not be greater than 14 characters.')
        .matches(
            /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
            'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.',
        ),
    confirm_password: yup
        .string()
        .required('You must confirm your password.')
        .oneOf([yup.ref('password'), null], 'Confirm password must be match.'),
};

export const RegistrationSchema = yup.object().shape({
    firstname: yup
        .string()
        .required('You must enter your first name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    middlename: yup
        .string()
        .nullable()
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Middle name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    email: yup
        .string()
        .required('Email address is required.')
        .matches(emailRegex, 'Please enter valid email and should not exceed 255 characters.'),
    lastname: yup
        .string()
        .required('You must enter your last name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    address_line_1: yup.string().required('You must enter your address line 1.')
        .test('len', 'Address line 1 must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    address_line_2: yup.string().test('len', 'Address line 2 must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    state_id: yup.number('You must select state.').required('You must select state.').typeError('You must select state.'),
    city_id: yup.number('You must select city.').required('Please select city').typeError('You must select city.'),
    terms_condition: yup
        .boolean()
        .oneOf([true], 'You must accept the terms and conditions'),
    ...passwordValidation,
});

export const signUpSchema = yup.object().shape({
    firstname: yup
        .string()
        .required('First name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    middlename: yup
        .string()
        .nullable()
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Middle name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    lastname: yup
        .string()
        .required('Last name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    address_line_1: yup.string().required('Address line 1 is required.')
        .test('len', 'Address line 1 must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    address_line_2: yup.string().test('len', 'Address line 2 must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    company_name: yup
        .string()
        .required('Company name is required.')
        .matches(alphabetWithSpace, 'Only alphabet and space allowed for this field.').required('Company name is required')
        .max(100, 'Company name must be less than 100 characters.'),
    email: yup
        .string()
        .required('Email address is required.')
        .matches(emailRegex, 'Please enter valid email and should not exceed 255 characters.'),
    mobile_number: yup
        .string()
        .required('Mobile number is required')
        .matches(phoneRegExp, 'Please enter valid mobile number.'),
    skype_id: yup
        .string()
        .trim()
        .max(100, 'Skype ID must be less than 100 characters.')
        .optional()
        .nullable()
        .test(
            'is-username-or-email',
            'Must be a valid email or username',
            (value) => {
                if (!value) return true;
                return emailRegex.test(value) || usernameRegex.test(value);
            },
        ),
    time_zone: yup.string().required('Time zone is required.'),
    country_id: yup.string().required('Country is required.'),
    state_id: yup.number('State is required.').required('State is required.').typeError('State is required.'),
    city_id: yup.number('City is required.').required('City is required').typeError('City is required.'),
    zip_code: yup
        .string()
        .required('ZIP code is required')
        .matches(alphaNumeric, 'Only alphanumeric are allowed for this field.')
        .test('len', 'ZIP code must be less than or equal to 7 characters.', (val) => (val ? val.length <= 7 : true)),
    terms_condition: yup
        .boolean()
        .oneOf([true], 'You must accept the terms and conditions'),
    otp: yup.string().min(6, 'OTP should contains minimum 6 digits.'),
    ...passwordValidation,
});
export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .required('Please enter email address.')
        .matches(emailRegex, 'Please enter valid email address.'),
    password: yup.string().required('Please enter password'),
});

const UserCommonSchema = {
    title: yup.string().required('Please select title.'),
    first_name: yup
        .string()
        .required('Please enter first name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    middle_name: yup
        .string('Please enter valid data.')
        .nullable()
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    last_name: yup
        .string()
        .required('Please enter last name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
    mobile_no: yup.number().required('Please enter mobile number.'),
    email: yup
        .string()
        .required('Please enter email address.')
        .matches(emailRegex, 'Please enter valid email address.'),
    country_code: yup.string().required('Please enter country code.'),
};
export const passwordSchema = yup.object({
    ...passwordValidation,
});

export const CustomerSchema = yup.object({
    ...UserCommonSchema,
    ...passwordValidation,
    referral_code: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Please enter valid referral code.')
        .nullable(),
});
export const CustomerUpdateSchema = yup.object({
    ...UserCommonSchema,
    referral_code: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Please enter valid referral code.')
        .nullable(),
});

export const passwordChangeSchema = yup.object({
    oldPassword: yup
        .string()
        .required('You must enter a password.')
        .min(8, 'Your password must have at least 8 characters.')
        .matches(
            /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
            'You have entered an invalid password format.',
        ),
    ...passwordValidation,
});

export const wireTransferPaymentSchema = yup.object({
    amount: yup.number().required('Amount is required').positive('Amount must be positive').typeError('Amount must be number'),
    image: yup.mixed()
        .required('Image is required')
        .test('fileSize', 'The file must be less then 5mb', (value) => !value || (value && value.size <= 5 * 1024 * 1024))
        .test(
            'fileFormat',
            'Please upload only jpeg, jpg, pdf or png file.',
            (value) => !value || (value && ['image/jpeg', 'image/jpg', 'image/png', 'image/tif', 'image/gif', 'image/bmp', 'application/pdf'].includes(value.type)),
        ),
});

export const groupsValidationSchema = yup.object({
    name: yup.string().required('Name is required.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.')
        .test('len', 'Name must be less than 30phone_n characters', (val) => (val ? val.length < 30 : true)),
    terminations: yup.array().of(yup.string()).min(1, 'please add atleast one target.'),
});
export const terminationValidationSchema = yup.object({
    name: yup.string().required('Please enter name.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.')
        .test('len', 'Name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    set_working_hours: yup.boolean().notRequired(),
    timezone_id: yup.string().nullable().when('set_working_hours', {
        is: (set_working_hours) => set_working_hours === true,
        then: () => yup.string().required('Time zone is required.'),
        otherwise: () => yup.string().nullable().notRequired(),
    }),
    cap_settings_on: yup.string().typeError('Please select cap setting.').nullable(true),
    global_cap: yup
        .boolean(),
    monthly_cap: yup
        .boolean(),
    daily_cap: yup
        .boolean(),
    hourly_cap: yup
        .boolean(),
    // max_cuncurency: yup
    //     .boolean(),
    hourly_cuncurrency: yup
        .boolean(),
    // max_cuncurency_value: yup.number().when('max_cuncurency', {
    //     is: (max_cuncurency) => max_cuncurency === true,
    //     then: () => yup.number().required('Max Concurrency value is required.').positive('Max Concurrency value should be positive.').integer('Max Concurrency should not accept decimal value.')
    //         .max(Maximum5Digit, `Max concurrency must be less than or equal to ${Maximum5Digit}.`),
    //     otherwise: () => yup.number().notRequired().nullable(),
    // }),
    hourly_cuncurrency_value: yup.number().when('hourly_cuncurrency', {
        is: (hourly_cuncurrency) => hourly_cuncurrency === true,
        then: () => yup.number().required('Concurrency value is required.').positive('Concurrency value should be positive.').integer('Coucurrency should not accept decimal value.'),
        // .test('maxCurrancyValue', 'Hourly concurrency must be less then or  Max Concurrency', function (maxCurrancyValue) {
        //     if (this.resolve((yup.ref('max_cuncurency')))) {
        //         const cunnrancyValue = this.resolve(yup.ref('max_cuncurency_value'));
        //         return cunnrancyValue > maxCurrancyValue;
        //     }
        //     return true;
        // }),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    type: yup.string().required('Please select type.'),
    phone_number: yup.number()
        .when('type', {
            is: (type) => type === 'phone',
            then: () => yup.string().required('Phone number is required.')
                .matches(phoneRegExp, 'Please enter valid phone number along with country code.')
                .typeError('Phone number should be number.'),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    sip_endpoint: yup
        .string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string().required('SIP endpoint  is required.')
                .matches(urlValidate, 'Please enter valid url.')
                .test('len', 'SIP endpoint must be less than 50 characters', (val) => (val ? val.length < 50 : true)),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    sip_username: yup.string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string()
                .matches(alphaNumeric, 'Only alphanumeric values are allowed.')
                .test('len', 'SIP username must be less than 50 characters', (val) => (val ? val.length < 50 : true)).nullable(),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    sip_password: yup.string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string()
                .test('len', 'SIP password must be less than 15 characters', (val) => (val ? val.length < 15 : true)).nullable(),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    global_cap_value: yup.string()
        .when('global_cap', {
            is: (global_cap) => global_cap === true,
            then: () => yup.number().required('Global cap is required.').positive('Global cap should be positive.').max(Maximum5Digit, `Global cap value charge must be less than or equal to ${Maximum5Digit}.`),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    monthly_cap_value: yup.number()
        .when('monthly_cap', {
            is: (monthly_cap) => monthly_cap === true,
            then: () => yup.number().required('Monthly cap is required.').positive('Monthly cap should be positive.').max(Maximum5Digit, `Monthly cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-cap', 'Monthly cap must be less than global call cap.', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    daily_cap_value: yup.number()
        .when('daily_cap', {
            is: (daily_cap) => daily_cap === true,
            then: () => yup.number().required('Daily cap is required.').positive('Daily cap should be positive.').max(Maximum5Digit, `Daily cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-monthly', 'Daily cap must be less than global call cap.', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                })
                .test('is-less-than-montly-cap', 'Daily cap must be less than monthly cap', function (value) {
                    const monthlyCapValue = this.parent.monthly_cap_value;
                    const monthlyCap = this.parent.monthly_cap;
                    if (monthlyCap === true) {
                        return value < monthlyCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    hourly_cap_value: yup.number()
        .when('hourly_cap', {
            is: (hourly_cap) => hourly_cap === true,
            then: () => yup.number().required('Hours cap is required.').positive('Hourly cap should be positive.').max(Maximum5Digit, `Hourly cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-', 'Hourly cap must be less than global call cap. ', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                })
                .test('is-less-than-montly-cap', 'Hours cap must be lesst than monthly cap.', function (value) {
                    const monthlyCapValue = this.parent.monthly_cap_value;
                    const monthlyCap = this.parent.monthly_cap;
                    if (monthlyCap === true) {
                        return value < monthlyCapValue;
                    }
                    return true;
                })
                .test('is-less-than-daily-cap', 'Hours cap must be lesst than daily cap.', function (value) {
                    const dailyCapValue = this.parent.daily_cap_value;
                    const dailyCap = this.parent.daily_cap;
                    if (dailyCap === true) {
                        return value < dailyCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
});
export const campaignTerminationSchema = yup.object({
    priority: yup.number().required('Please enter priority.').positive('Priority must be positive')
        .max(Maximum5Digit, `Priority must be less than or equal to ${Maximum5Digit}.`)
        .typeError('Priority must be number'),
    weight: yup.number().required('Please enter weight.').positive('Priority must be positive').integer('Weight must be integer')
        .typeError('Weight must be number')
        .max(100, 'Weight must be less than or equal to 100.'),
    revenue: yup.number().required('Please enter revenue.').positive('Revenue must be positive')
        .max(Maximum5Digit, `Revenue must be less than or equal to ${Maximum5Digit}.`)
        .test(
            'is-decimal',
            'The revenue should be a decimal with maximum 2 digits after comma.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith2Digit.test(val);
                }
                return true;
            },
        )
        .typeError('Revenue must be number'),
    name: yup.string().required('Please enter name.').matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.')
        .test('len', 'Name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    set_working_hours: yup.boolean().notRequired(),
    timezone_id: yup.string().nullable().when('set_working_hours', {
        is: (set_working_hours) => set_working_hours === true,
        then: () => yup.string().required('Time zone is required.'),
        otherwise: () => yup.string().nullable().notRequired(),
    }),
    cap_settings_on: yup.string().typeError('Please select cap setting.').nullable(true),
    Rconversion_on: yup.string().required('Please select conversion on.'),
    start_call_length: yup.number().when('Rconversion_on', {
        is: (Rconversion_on) => Rconversion_on === 'call_length',
        then: () => yup.number().required('Please enter start_call_length.').positive('Start call length should be positive.')
            .max(Maximum5Digit, `Start call Length must be less than or equal to ${Maximum5Digit}.`)
            .test(
                'is-decimal',
                'The start call length should be a decimal with maximum 2 digits after comma.',
                (val) => {
                    if (val !== undefined) {
                        return decimalWith2Digit.test(val);
                    }
                    return true;
                },
            ),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    type: yup.string().required('Please select type.'),
    phone_number: yup.number()
        .when('type', {
            is: (type) => type === 'phone',
            then: () => yup.string().required('Phone number is required.')
                .matches(phoneRegExp, 'Please enter valid phone number along with country code.')
                .typeError('Phone number should be number.'),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    sip_endpoint: yup
        .string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string().required('SIP endpoint  is required.')
                .matches(urlValidate, 'Please enter valid url.')
                .test('len', 'SIP endpoint be less than 50 characters', (val) => (val ? val.length < 50 : true)),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    sip_username: yup.string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string()
                .matches(alphaNumeric, 'Only alphanumeric values are allowed.')
                .test('len', 'SIP username must be less than 50 characters', (val) => (val ? val.length < 50 : true)).nullable(),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    sip_password: yup.string()
        .when('type', {
            is: (type) => type === 'sip',
            then: () => yup.string()
                .test('len', 'SIP password must be less than 15 characters', (val) => (val ? val.length < 15 : true)).nullable(),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    // max_cuncurency_value: yup.number().when('max_cuncurency', {
    //     is: (max_cuncurency) => max_cuncurency === true,
    //     then: () => yup.number().required('Max concurrency value is required.').positive('Max concurrency value should be positive.').integer('Max Coucurrency should not accept decimal value.')
    //         .max(Maximum5Digit, `Max concurrency  must be less than or equal to ${Maximum5Digit}.`),
    //     otherwise: () => yup.number().notRequired().nullable(),
    // }),
    hourly_cuncurrency_value: yup.number().when('hourly_cuncurrency', {
        is: (hourly_cuncurrency) => hourly_cuncurrency === true,
        then: () => yup.number().required('Concurrency value is required.').positive('Concurrency value should be positive.').integer('Coucurrency should not accept decimal value.')
            .max(Maximum5Digit, `Concurrency  must be less than or equal to ${Maximum5Digit}.`),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    global_cap_value: yup.string()
        .when('global_cap', {
            is: (global_cap) => global_cap === true,
            then: () => yup.number().required('Global cap is required.').positive('Global cap should be positive.').max(Maximum5Digit, `Globally cap value charge must be less than or equal to ${Maximum5Digit}.`),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    monthly_cap_value: yup.number()
        .when('monthly_cap', {
            is: (monthly_cap) => monthly_cap === true,
            then: () => yup.number().required('Monthly cap is required.').positive('Monthly cap should be positive.').max(Maximum5Digit, `Monthly cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-cap', 'Monthly cap must be less than global call cap.', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    daily_cap_value: yup.number()
        .when('daily_cap', {
            is: (daily_cap) => daily_cap === true,
            then: () => yup.number().required('Daily cap is required.').positive('Daily cap should be positive.').max(Maximum5Digit, `Daily cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-monthly', 'Daily cap must be less than global call cap.', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                })
                .test('is-less-than-montly-cap', 'Daily cap must be less than monthly cap', function (value) {
                    const monthlyCapValue = this.parent.monthly_cap_value;
                    const monthlyCap = this.parent.monthly_cap;
                    if (monthlyCap === true) {
                        return value < monthlyCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
    hourly_cap_value: yup.number()
        .when('hourly_cap', {
            is: (hourly_cap) => hourly_cap === true,
            then: () => yup.number().required('Hours cap is required.').positive('Hourly cap should be positive.').max(Maximum5Digit, `Hourly cap value charge must be less than or equal to ${Maximum5Digit}.`)
                .test('is-less-than-global-', 'Hourly cap must be less than global call cap. ', function (value) {
                    const globalCapValue = this.parent.global_cap_value;
                    if (this.parent.global_cap === true) {
                        return value < globalCapValue;
                    }
                    return true;
                })
                .test('is-less-than-montly-cap', 'Hours cap must be lesst than monthly cap.', function (value) {
                    const monthlyCapValue = this.parent.monthly_cap_value;
                    const monthlyCap = this.parent.monthly_cap;
                    if (monthlyCap === true) {
                        return value < monthlyCapValue;
                    }
                    return true;
                })
                .test('is-less-than-daily-cap', 'Hours cap must be lesst than daily cap.', function (value) {
                    const dailyCapValue = this.parent.daily_cap_value;
                    const dailyCap = this.parent.daily_cap;
                    if (dailyCap === true) {
                        return value < dailyCapValue;
                    }
                    return true;
                }),
            otherwise: () => yup.number().notRequired().nullable(),
        }),
});

export const campaignGroupSchema = yup.object({
    weight: yup.number().required('Please enter weight.'),
    revenue: yup.number().required('Please enter revenue.').positive('Revenue must be positive')
        .max(Maximum5Digit, `Revenue must be less than or equal to ${Maximum5Digit}.`)
        .test(
            'is-decimal',
            'The revenue should be a decimal with maximum 2 digits after comma.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith2Digit.test(val);
                }
                return true;
            },
        )
        .typeError('Revenue must be number'),
    name: yup.string().required('Please enter name.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.')
        .test('len', 'Name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    Rconversion_on: yup.string().required('Please select conversion on.'),
    start_call_length: yup.number().when('Rconversion_on', {
        is: (Rconversion_on) => Rconversion_on === 'call_length',
        then: () => yup.number().required('Please enter start_call_length.').positive('Start call length should be positive.')
            .max(Maximum5Digit, `Start call Length must be less than or equal to ${Maximum5Digit}.`)
            .test(
                'is-decimal',
                'The start call length should be a decimal with maximum 2 digits after comma.',
                (val) => {
                    if (val !== undefined) {
                        return decimalWith2Digit.test(val);
                    }
                    return true;
                },
            ),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    terminations: yup.array().of(yup.string()).min(1, 'please add atleast one target.'),
});
export const generalSchema = yup.object({
    flag_duplicate_calls: yup.string().nullable(),
    call_recording: yup.boolean(),
    recording_type: yup.string().nullable()
        .when('call_recording', {
            is: (call_recording) => call_recording === true,
            then: () => yup.string().required('Audio type is required')
                .oneOf(['mono', 'stereo'], 'Invalid audio type'),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    call_length_greater_than: yup.number().when('flag_duplicate_calls', {
        is: (flag_duplicate_calls) => flag_duplicate_calls === 'call_length',
        then: () => yup.number().required('Please enter call length.').positive('Count should be greater than 0.'),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    duplicate_hours: yup.number().when('flag_duplicate_calls', {
        is: (flag_duplicate_calls) => flag_duplicate_calls === 'call_length' || flag_duplicate_calls === 'connected',
        then: () => yup.number()
            .required('Previous Caller Identification is required.')
            .positive('Negative value is not allowed')
            .integer('Decimal value is not allowed')
            .typeError('Decimal value is not allowed'),
        otherwise: () => yup.number().notRequired(),
    }),
    same_target: yup.string().when('flag_duplicate_calls', {
        is: (flag_duplicate_calls) => flag_duplicate_calls === 'call_length' || flag_duplicate_calls === 'connected',
        then: () => yup.string().required('Previous Caller Direction is required.'),
        otherwise: () => yup.string().notRequired(),
    }),
    number_of_call_attempts: yup.number()
        .required('Please enter call attempts.')
        .max(Maximum5Digit, `Number of call attempts must be less than or equal to ${Maximum5Digit}.`)
        .positive('Number of call attempts should be greater that 0.')
        .integer('Number of call attempts must be integer'),
    name: yup.string().trim('Only space not allowed.').required('Please enter name.').max(50, 'Maxmimum 50 characters are allowed.')
        .matches(alphaNumeric, 'Only alphanumeric values are allowed.'),
});

export const routingInfoSchema = yup.object({
    priority: yup.number().when('type', {
        is: (type) => type === 'termination',
        then: () => yup.number().required('Please enter priority.').positive('Priority must be positive').integer('Priority must be integer')
            .max(Maximum5Digit, `Priority must be less than or equal to ${Maximum5Digit}.`)
            .typeError('Priority must be number'),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
    weight: yup.number().required('Please enter weight.').positive('Priority must be positive').integer('Weight must be integer')
        .typeError('Weight must be number')
        .max(100, 'Weight must be less than or equal to 100.'),
    conversion_on: yup.string().required('Please select conversion on.'),
    revenue: yup.number().required('Please enter revenue.').positive('Revenue must be positive')
        .max(Maximum5Digit, `Revenue must be less than or equal to ${Maximum5Digit}.`)
        .test(
            'is-decimal',
            'The revenue should be a decimal with maximum 2 digits after comma.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith2Digit.test(val);
                }
                return true;
            },
        )
        .typeError('Revenue must be number'),
    start_call_length: yup.number().when('conversion_on', {
        is: (conversion_on) => conversion_on === 'call_length',
        then: () => yup.number().required('Please enter start call length.').positive('Start call length should be positive.')
            .max(Maximum5Digit, `Start call Length must be less than or equal to ${Maximum5Digit}.`)
            .test(
                'is-decimal',
                'The start call length should be a decimal with maximum 2 digits after comma.',
                (val) => {
                    if (val !== undefined) {
                        return decimalWith2Digit.test(val);
                    }
                    return true;
                },
            ),
        otherwise: () => yup.number().notRequired().nullable(),
    }),
});

export const NumberSchema = yup.object({
    type: yup.string().required('Please select number type.'),
    country_id: yup.string().required('Country is required.'),
    number_type: yup.string().required('Please select DID number type.'),
    number_range_count: yup.number()
        .when('number_type', {
            is: (type) => type === 'range',
            then: () => yup.number()
                .required('Please enter number count.').positive('Count should be greater that 0.').integer('Number range should not accept decimal value. ')
                .max(1000, 'Number range  must be less than or equal to 1000.'),
            otherwise: () => yup.string().notRequired().nullable(),
        }),
    match_number: yup.string().when('advance_search', {
        is: (advance_search) => advance_search,
        then: () => yup
            .number()
            .max('999999999999999999', 'Phrase or number field should be less than or equal to 18 digits.')
            .required('Phrase and number is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    match_type: yup.string().when('advance_search', {
        is: (advance_search) => advance_search,
        then: () => yup
            .string()
            .required('This field is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
});
export const roleSchema = yup.object({
    name: yup.string().required('Please enter name.').matches(alphabetWithSpace, 'Only alphabet and space allowed for this field.'),
});
export const UserValidationSchema = yup.object({
    firstname: yup
        .string()
        .required('You must enter your first name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    lastname: yup
        .string()
        .required('You must enter your last name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    email: yup
        .string()
        .required('Please enter email address.')
        .matches(emailRegex, 'Please enter valid email and should not exceed 255 characters.'),
    role_id: yup.string().required('Role is required.'),
    password: yup
        .string()
        .required('You must enter a password.')
        .min(8, 'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.')
        .max(14, 'Password should not be greater than 14 characters.')
        .matches(
            /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
            'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.',
        ),
    confirm_password: yup
        .string()
        .required('You must confirm your password.')
        .oneOf([yup.ref('password'), null], 'Confirm password must be match.'),

});
export const UserValidationSchemaUpdate = yup.object({
    firstname: yup
        .string()
        .required('You must enter your first name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    lastname: yup
        .string()
        .required('You must enter your last name.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    email: yup
        .string()
        .required('Please enter email address.')
        .matches(emailRegex, 'Please enter valid email and should not exceed 255 characters.'),
    role_id: yup.string().required('Role name is required.'),
});
export const platformPlanValidationSchema = yup.object({
    name: yup
        .string()
        .required('Plan name is required.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
        .test('len', 'Plan name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    country_id: yup
        .string()
        .required('The country is mandatory.'),
    description: yup
        .string()
        .nullable()
        .test('len', 'Plan description must be less than 255 characters.', (val) => (val ? val.length < 255 : true)),
    plan_mode: yup.string().required('Plan mode is required.'),
    platform_charge: yup.number().min(0, 'Platform charge must be a positive.').max(maximumCharge, `Platform charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    toll_free_number_rental_charge: yup.number().min(0, 'Toll-free number charge must be a positive.')
        .max(maximumCharge, `Toll-free number charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    local_number_rental_charge: yup.number().min(0, 'Local number charge must be a positive.')
        .max(maximumCharge, `Local number charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    local_number_call_charge: yup.number().min(0, 'Local call charge must be a positive.')
        .max(maximumCharge, `Local number call charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    toll_free_number_call_charge: yup.number()
        .min(0, 'Toll-free call charge must be a positive.')
        .max(maximumCharge, `Toll-free number call charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    recording_call_charge: yup.number()
        .min(0, 'Recording call charge must be a positive.')
        .max(maximumCharge, `Recording call charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    recording_storage_charge: yup.number().min(0, 'Storage charge must be a positive.')
        .max(maximumCharge, `Storage Charge must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 3 digits are allowed after decimal point.',
            (val) => {
                if (val !== undefined) {
                    return decimalWith3Digit.test(val);
                }
                return true;
            },
        ),
    storage_limit: yup.number().nullable().min(0, 'Storage Charge must be a positive.').max(maximumCharge, `Storage limit must be less than or equal to ${maximumCharge}.`)
        .test(
            'is-decimal',
            'Only 2 digits are allowed after decimal point for storage limit(GB).',
            (val) => {
                if (val !== undefined && Math.round(val) !== 0) {
                    return decimalWith2Digit.test(val);
                }
                return true;
            },
        ),
    priority: yup.number()
        .positive('Negative value is not allowed')
        .integer('Decimal value is not allowed')
        .required('Priority is required')
        .typeError('Decimal value is not allowed'),
});

export const AdvertiserValidationSchema = yup.object({
    firstname: yup
        .string()
        .required('first name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    lastname: yup
        .string()
        .required('last name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    middlename: yup
        .string()
        .nullable()
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Middle name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    email: yup
        .string()
        .required('Email address is required.')
        .matches(emailRegex, 'Please enter valid email and should not exceed 255 characters.')
        .test(255, 'Email ID must be max 255 characters long.', (val) => (val ? val.length <= 255 : true)),
    mobile_number: yup
        .string()
        .required('Mobile number is required')
        .matches(phoneRegExp, 'Please enter valid mobile number.'),
    address_line_1: yup
        .string()
        .required('Address line 1 is required.')
        .test('len', 'Address line 1 must be less than 100 characters.', (val) => (val ? val.length < 100 : true)),
    address_line_2: yup
        .string()
        .test('len', 'Address line 2 must be less than 100 characters.', (val) => (val ? val.length < 100 : true)),
    state_id: yup.string().required('State is required.'),
    city_id: yup.string().required('City is required.'),
    country_id: yup.string().required('Country is required.'),
    time_zone: yup.string().required('Time zone is required.'),
    zip_code: yup
        .string()
        .required('ZIP code is required')
        .matches(alphaNumeric, 'Only alphanumeric are allowed for this field.')
        .test('len', 'ZIP code must be less than or equal to 7 characters.', (val) => (val ? val.length <= 7 : true)),
    company_name: yup
        .string()
        .required('Company name is required.')
        .matches(alphabetWithSpace, 'Only alphabet and space allowed for this field.').required('Company name is required')
        .max(100, 'Company name must be less than 100 characters.'),
    skype_id: yup
        .string()
        .trim()
        .max(100, 'Company name must be less than 100 characters.')
        .optional()
        .nullable()
        .test(
            'is-username-or-email',
            'Must be a valid email or username',
            (value) => {
                if (!value) return true;
                return emailRegex.test(value) || usernameRegex.test(value);
            },
        ),
});
export const CsvFileValidationSchema = yup.object({
    csv: yup.mixed()
        .required('Advertiser CSV file is required')
        .test('fileType', 'Invalid file type. Only CSV files are allowed.', (value) => {
            if (!value) return true;
            const validExtensions = ['csv'];
            const extension = value.name.split('.').pop();
            return validExtensions.includes(extension);
        })
        .test('fileSize', 'File size is too large. Max allowed size is 10MB.', (value) => value && value.size <= 10 * 1024 * 1024,
        ),
});
export const addDiDValidationSchema = yup.object().shape({
    type: yup.string().required('Number type is required'),
    number: yup.string()
        .required('DID number is required')
        .matches(phoneRegExp, 'Please enter valid DID number.'),
    country_id: yup.string().required('Country is required'),
    from_carrier: yup.string().required('From Carrier is required'),
    area_code: yup.number().notOneOf([0], 'Area code value cannot be 0').min(9, 'Area code should be greater than 1 digit.').max('999999999999999999', 'Area code should be less than or equal to 18 digits.')
        .typeError('Area code must be number')
        .nullable(),
});
export const KYCDocumentValidationSchema = yup.object({
    name: yup
        .string()
        .required('Document name is required.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
        .test('len', 'Plan name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    country_id: yup
        .string()
        .required('The country is mandatory.'),
});
export const DidCsvFileValidationSchema = yup.object({
    csv: yup.mixed()
        .required('DID Inventory CSV file is required')
        .test('fileType', 'Invalid file type. Only CSV files are allowed.', (value) => {
            if (!value) return true;
            const validExtensions = ['csv'];
            const extension = value.name.split('.').pop();
            return validExtensions.includes(extension);
        })
        .test('fileSize', 'File size is too large. Max allowed size is 10MB.', (value) => value && value.size <= 10 * 1024 * 1024,
        ),
    carrier: yup.string().required('Carrier selection is required.'),
});
export const termsAndConditionValidationSchema = yup.object({
    country_id: yup.string().required('Country is required.'),
    description: yup.string().required('Description is required.'),
});
export const carrierValidtionSchema = yup.object({
    carrier_options: yup.string().required('Carrier option is required.'),
    carrier_type: yup.string().required('Carrier type is required.'),
    name: yup.string().when('carrier_options', {
        is: (carrier_options) => carrier_options === 'add_new',
        then: () => yup.string().required('Carrier name is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.')
            .test('len', 'Carrier name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    carrier_id: yup.string().when('carrier_options', {
        is: (carrier_options) => carrier_options === 'select_from_available',
        then: () => yup.string().required('Carrier is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    provider_id: yup.string().nullable().when('carrier_id', {
        is: (carrier_id) => Boolean(carrier_id) === true,
        then: () => yup.string().required('Account name is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    register: yup.string().required('Register is required.'),
    destination_port: yup.number().required('Desitnation port is required.').integer('Destination port should not accept decimal value')
        .positive('Desitnation port must be positive')
        .max(highestDestinationPort, `Destination port cannot exceed ${highestDestinationPort}`)
        .typeError('Desitnation port must be number'),
    destination_ip: yup.string()
        .when('carrier_type', {
            is: (carrier_type) => carrier_type === 'outbound',
            then: () => yup.string().required('Outbound destination IP/domain  is required.')
                .matches(urlValidate, 'Please enter valid url.')
                .test('len', 'Outbound destination IP/domain must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
            otherwise: () => yup.string().required('Destination IP/domain  is required.')
                .matches(urlValidate, 'Please enter valid url.')
                .test('len', 'Destination IP/domain must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
        }),
    username: yup.string()
        .when('carrier_type', {
            is: (carrier_type) => carrier_type === 'inbound' || carrier_type === 'both',
            then: () => yup.string().required('Username is required.').matches(alphaNumeric, 'Only alphanumeric values are allowed.')
                .test('len', 'Username must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
            otherwise: () => yup.string().notRequired().matches(alphaNumeric, 'Only alphanumeric values are allowed.')
                .test('len', 'Username must be less than 50 characters.', (val) => (val ? val.length < 50 : true))
                .nullable(),
        }),
    password: yup.string().when('carrier_type', {
        is: (carrier_type) => carrier_type === 'inbound' || carrier_type === 'both',
        then: () => yup.string().test('len', 'Password must be less than 15 characters.', (val) => (val ? val.length < 15 : true)).required('Password is required.'),
        otherwise: () => yup.string().test('len', 'Password must be less than 15 characters.', (val) => (val ? val.length < 15 : true)).notRequired().nullable(),
    }),
    assigned_codec: yup.array().of(yup.object())
        .when('audio_codec_support', {
            is: (audio_codec_support) => audio_codec_support === 'custom',
            then: () => yup.array().of(yup.object()).min(1, 'Please add atleast one codec.'),
            otherwise: () => yup.array().notRequired(),
        }),
    expiry: yup.number().required('Expiry time is required.')
        .positive('Expiry time must be positive.')
        .min(10, 'Expiry time should be greater than and equal to 10.')
        .max(99999, 'Expiry time cannot exceed 99999')
        .typeError('Expiry time must be number.')
        .test(
            'is-decimal',
            'The expiry time should be a decimal with maximum 2 digits after comma.',
            (val) => {
                if (val !== undefined) {
                    return patternTwoDigisAfterComma.test(val);
                }
                return true;
            },
        ),
});

export const providerSchema = yup.object({
    name: yup.string().matches(alphaNumericWithSpace, 'Only alphanumeric and space allowed for this field.').required('Account name is required.'),
    carrier_id: yup.number().nullable().required('Provider is required.'),
    status: yup.string(),
    connection_id: yup.string().required('Connection ID is required.'),
    token: yup.string().required('Token is required.'),
});

export const whiteListIpAddressSchema = yup.object({
    ip: yup.string().required('IP address is required.').matches(IPaddressRegex, 'Please enter valid IP address.'),
    description: yup.string().test('len', 'Description must be less than 100 characters', (val) => (val ? val.length < 100 : true)).nullable(),
    name: yup
        .string()
        .required('You must enter your IP name.')
        .matches(/^[A-Za-z ]*$/, 'Only alphabets are allowed for this field.')
        .test('len', 'IP name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
});
export const ProfileValidationSchema = yup.object({
    firstname: yup
        .string()
        .required('First name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'First name must be less than 30 characters', (val) => (val ? val.length < 30 : true)),
    lastname: yup
        .string()
        .required('Last name is required.')
        .matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
        .test('len', 'Last name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    middlename: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().matches(onlyAlphabet, 'Only alphabets are allowed for this field.')
            .test('len', 'Middle name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    mobile_number: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('Mobile number is required').matches(phoneRegExp, 'Please enter valid mobile number.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    address_line_1: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('Address line 1 is required.').test('len', 'Address line 1 must be less than 100 characters.', (val) => (val ? val.length < 100 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    address_line_2: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().test('len', 'Address line 2 must be less than 100 characters.', (val) => (val ? val.length < 100 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    company_name: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('Company name is required.')
            .matches(alphabetWithSpace, 'Only alphabet and space allowed for this field.').required('Company name is required')
            .max(100, 'Company name must be less than 100 characters.'),
    }),
    state_id: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('State is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    time_zone: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('Time Zone is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    city_id: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('City is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    zip_code: yup.string().when('role', {
        is: (role) => role === 'system',
        then: () => yup.string().required('ZIP code is required').matches(alphaNumeric, 'Only alphanumeric are allowed for this field.').test('len', 'ZIP code must be less than or equal to 7 characters.', (val) => (val ? val.length <= 7 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    profile_image: yup.mixed()
        .test('fileSize', 'File size is too large. Max allowed size is 5MB.', (value) => !value || (value && value.size <= 1048576 * 5))
        .test(
            'fileFormat',
            'Please upload only jpeg, jpg or png file.',
            (value) => !value || (value && ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)),
        ),
});
export const changePasswordValidation = yup.object({
    current_password: yup.string().required('Current Password is required.'),
    new_password: yup
        .string()
        .required('You must enter a password.')
        .min(8, 'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.')
        .max(14, 'Password should not be greater than 14 characters.')
        .matches(
            /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g,
            'Password must contain 8 or more characters with at least one of each: uppercase or lowercase, number and special.',
        ),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('new_password'), null], 'Confirm password must be match.')
        .required('You must confirm your password.'),
});
export const promotionalPlanValidationSchema = yup.object({
    name: yup
        .string()
        .required('Promotional plan name is required.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
        .test('len', 'Promotional plan name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
});
export const expenseValidationSchema = yup.object({
    campaign_id: yup.string().required('Campaign is required.'),
    date: yup.string().required('Date is required.'),
});

export const paymentMethodValidationSchema = yup.object({
    logo: yup.string().when('setLogo', {
        is: (setLogo) => setLogo === true,
        then: () => yup.mixed()
            .required('Logo is required')
            .test('fileSize', 'The file must be less then 5 MB', (value) => !value || (value && value.size <= 5 * 1024 * 1024))
            .test(
                'fileFormat',
                'Please upload only jpeg, jpg, pdf or png file.',
                (value) => !value || (value && ['image/jpeg', 'image/jpg', 'image/png', 'image/tif', 'image/gif'].includes(value.type)),
            ),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    qr_code_path: yup.string().when(['setBitCoin', 'slug'], {
        is: (setBitCoin, slug) => setBitCoin === true && slug === BITCOIN_SLUG,
        then: () => yup.mixed()
            .required('QR code image is required')
            .test('fileSize', 'The file must be less then 5 MB', (value) => !value || (value && value.size <= 5 * 1024 * 1024))
            .test(
                'fileFormat',
                'Please upload only jpeg, jpg, pdf or png file.',
                (value) => !value || (value && ['image/jpeg', 'image/jpg', 'image/png', 'image/tif', 'image/gif'].includes(value.type)),
            ),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    name: yup
        .string()
        .required('Payment method name is required.')
        .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
        .test('len', 'Payment method name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
    bank_name: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('Bank name is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
            .test('len', 'Bank name must be less than 50 characters.', (val) => (val ? val.length < 50 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    account_number: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('Account number is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
            .test('len', 'Account number must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    account_name: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('Account holder name is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.')
            .test('len', 'Account holder name must be less than 30 characters.', (val) => (val ? val.length < 30 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    bank_address: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('Bank address is required.')
            .test('len', 'Bank address must be less than 200 characters.', (val) => (val ? val.length < 200 : true)),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    ifsc_code: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('IFSC code is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    routing_number: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_USA_SLUG,
        then: () => yup
            .number()
            .required('Routing number is required.')
            .typeError('Routing number must be number'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    swift_code: yup.string().when('slug', {
        is: (slug) => slug === WIRETRANSFER_USA_SLUG || slug === WIRETRANSFER_SLUG,
        then: () => yup
            .string()
            .required('SWIFT Code is required.')
            .matches(alphaNumericWithSpace, 'Only alphanumeric are allowed for this field.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    RAZORPAY_KEY_ID: yup.string().when('slug', {
        is: (slug) => slug === RAZORPAY_SLUG,
        then: () => yup
            .string()
            .required('Razor key ID is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    RAZORPAY_KEY_SECRET: yup.string().when('slug', {
        is: (slug) => slug === RAZORPAY_SLUG,
        then: () => yup
            .string()
            .required('Razor Secret Key is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
    wallet_id: yup.string().when('slug', {
        is: (slug) => slug === BITCOIN_SLUG,
        then: () => yup
            .string()
            .required('Wallet ID is required.'),
        otherwise: () => yup.string().notRequired().nullable(),
    }),
});
export const dynamicContentValidation = yup.object().shape({
    title: yup.string().required('Title is required.').max(50, 'Maxmimum 50 characters are allowed.'),
    title_link: yup.string().url('Invalid URL format').required('Title link is required.'),
    images: yup.array().of(
        yup.object().shape({
            image: yup.mixed().when('newImg', {
                is: (newImg) => newImg === true,
                then: () => yup.mixed().required('Image is required.')
                    .test('fileSize', 'The file must be less then 5MB', (value) => !value || (value && value.size <= 5 * 1024 * 1024))
                    .test(
                        'fileFormat',
                        'Please upload only jpeg, jpg or png file.',
                        (value) => !value || (value && ['image/jpeg', 'image/jpg', 'image/png', 'image/tif', 'image/gif', 'image/bmp'].includes(value.type)),
                    ),
                otherwise: () => yup.mixed().notRequired().nullable(),
            }),
            link: yup.string().url('Invalid URL format').required('Image link is required.'),
        }),
    ).required('At least one image is required'),
    description: yup.string().required('Description is required.'),
    button_text: yup.string().required('Button text is required.'),
    button_url: yup.string().url('Invalid URL format').required('Button link is required.'),
});
