import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
    name: 'errorSlice',
    initialState: {
        networkError: null,
    },
    reducers: {
        setNetworkError: (state, action) => {
            state.networkError = action.payload;
        },
        clearNetworkError: (state) => {
            state.networkError = null;
        },
    },
});

export const { setNetworkError, clearNetworkError } = errorSlice.actions;
export default errorSlice.reducer;
