/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-import-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
    useCallback, useEffect, useState,
} from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes, { object } from 'prop-types';
import { useTable } from 'react-table';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Loader from '../../screens/loader';
import Reportchart from '../../screens/reportChart';
import { handleReportData, handleSearchParams } from '../../app/features/CommonSlice';
import { getModulePermission } from '../../Util';
import { ADVERTISER, PLATFORM_PROVIDER } from '../../Constant';
import 'react-datepicker/dist/react-datepicker.css';
import SearchableAdvertiserSelect from '../Common/CommonSerchableWithapi';
import { Toast } from '../Common/Toaster';
import filterIcon from '../../assets/images/filterIcon.png';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
import SearchableSelect from '../Common/CommonSearchableSelect';
import CommonChipsFilter from '../Common/CommonChipsFilter/CommonChipsFilter';
import FilterTable from '../Common/filterTable';

const BasicTable = ({
    title, handleClick, btnText, data, isLoading, isFetching, liveCall, module,
    columns, createButton, deletePermission, editPermission, showPagination, handleFilterTablePage, currentFilterTablePage,
    importCsv, handleImportCsv, columnFilter, statusFilter, componentName, searching, handleExportCsv, ExportCsv, handleExportPdf, ExportPdf, showChart, showCommonChipsFilter, filterOptions, setFilterOptions, statusOptions, setStatusOptions, refetchDetailRecord,
    showfilterTable, setIdObjectForAdvertiser, idObjectForAdvertiser,
    individualColumns, individualData, dataLoader, timeInterval, setTimeInterval,
    isLiveCallTableOpen, setIsLiveCallTableOpen,
    showCalander, showTimeZone, datepickerRange, advertiserSearch, datechangeFilter, isRefetch, handleRefetchRecord, notRequiredMaxDateInDatePicker, graphshow, graphOption, initialFilter, graphTitle,
}) => {
    const [search, setSearch] = useState('');
    const { searchParams, reportData } = useSelector((store) => store.common);
    const { userType, user } = useSelector((store) => store.auth);
    const createPermission = getModulePermission('create', 'button');
    const dispatch = useDispatch();
    const [tableColumn, setTableColumn] = useState(columns);
    const [allChecked, setAllChecked] = useState(true);
    const allStatusFilter = statusFilter;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [liveCallData, setLiveCallData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [statusFilterData, setStatusFilterData] = useState();
    const [advertiserId, setAvertiserId] = useState('');
    const [blackNumberExcluded, setBlackNumberExcluded] = useState(null);
    const [timeZone, setTimeZone] = useState('');
    const datechange = (dateObject) => {
        if (dateObject) {
            const year = dateObject.getFullYear();
            const month = `0${dateObject.getMonth() + 1}`.slice(-2);
            const day = `0${dateObject.getDate()}`.slice(-2);

            const withHyphens = [year, month, day].join('-');
            return withHyphens;
        }
        return null;
    };
    const currentDate = new Date();
    const handleAdvertiserChange = (val) => {
        setAvertiserId(val);
    };
    useEffect(() => {
        if (liveCall) {
            setLiveCallData(data);
        }
    }, [data]);
    useEffect(() => {
        if (liveCall) {
            if (advertiserId) {
                const newFilteredData = data.filter((item) => item.advertiser_id === advertiserId);
                setLiveCallData(newFilteredData);
                setFilteredData(newFilteredData);
            } else {
                setLiveCallData(data);
            }
        }
    }, [data, advertiserId]);
    useEffect(() => {
        if (!liveCall) {
            const filterData = allStatusFilter?.length > 0 ? allStatusFilter?.map((item) => item.value) : [];
            setStatusFilterData(filterData.toString());
        }
    }, [statusFilter]);
    useEffect(() => {
        dispatch(handleSearchParams({
            ...searchParams, advertiser_id: advertiserId, page: 1,
        }));
        dispatch(handleReportData({ ...reportData, advertiser_id: advertiserId }));
    }, [advertiserId]);
    useEffect(() => {
        dispatch(handleReportData({
            ...reportData,
            timezone_id: timeZone,
        }));
        if (advertiserId) {
            dispatch(handleSearchParams({
                ...searchParams,
                timezone_id: timeZone,
                advertiser_id: advertiserId,
            }));
        } else {
            dispatch(handleSearchParams({
                ...searchParams,
                timezone_id: timeZone,
            }));
        }
    }, [timeZone]);

    useEffect(() => {
        setStartDate(datepickerRange?.startDate);
        setEndDate(datepickerRange?.endDate);
        dispatch(handleReportData({
            ...reportData,
            timezone_id: timeZone,
            startDate: datechange(datepickerRange?.startDate),
            endDate: datechange(datepickerRange?.endDate),
        }));
    }, [datepickerRange]);
    const setBlockedNumberFilter = (val) => {
        setBlackNumberExcluded(val);
        setIdObjectForAdvertiser({ ...idObjectForAdvertiser, blacklisted_caller: val });
        setTimeout(() => {
            refetchDetailRecord();
        }, 100);
    };
    const handleDateRangeChange = (dates) => {
        datechangeFilter({ startDate: dates[0], endDate: dates[1] });
        setStartDate(dates[0]);
        setEndDate(dates[1]);
        if (dates[0] && dates[1]) {
            if (statusFilterData) {
                dispatch(handleReportData({
                    ...reportData,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                }));

                dispatch(handleSearchParams({
                    keyword: search,
                    page: 1,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    status: statusFilterData,
                    timezone_id: timeZone,
                }));
            } else {
                dispatch(handleReportData({
                    ...reportData,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                    advertiser_id: advertiserId,
                }));
                dispatch(handleSearchParams({
                    keyword: search,
                    page: 1,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                    advertiser_id: advertiserId,
                }));
            }
        } else if (dates[0] === null && dates[1] === null) {
            Toast({ type: 'error', message: 'Date range selection mandatory.' });
            // dispatch(handleReportData({
            //     ...reportData, startDate: '', endDate: '', timezone_id: timeZone, advertiser_id: advertiserId,
            // }));
            // dispatch(handleSearchParams({
            //     ...searchParams, startDate: '', endDate: '', timezone_id: timeZone, advertiser_id: advertiserId,
            // }));
        }
    };
    useEffect(() => {
        if (timeInterval === 1) {
            setStartDate(new Date());
            setEndDate(new Date());
            dispatch(handleSearchParams({
                ...searchParams,
                // startDate: datechange(new Date()),
                // endDate: datechange(new Date()),
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            }));
            setTimeInterval(1);
        }
    }, [timeInterval]);

    const handleChange = (id) => (e) => {
        const keyword = e.target.value;
        setSearch(e.target.value);
        if (liveCall) {
            let searchedData;
            if (id) {
                searchedData = filteredData.filter((item) => Object.values(item).some((value) => value.toString().toLowerCase().includes(keyword.toLowerCase())));
                if (keyword !== '') {
                    setLiveCallData(searchedData);
                } else {
                    setLiveCallData(filteredData);
                }
            } else {
                searchedData = liveCallData.filter((item) => Object.values(item).some((value) => value.toString().toLowerCase().includes(keyword.toLowerCase())));
                if (keyword !== '') {
                    setLiveCallData(searchedData);
                } else {
                    setLiveCallData(data);
                }
            }
        } else {
            const checkStatusExist = searchParams;
            if (checkStatusExist?.status !== undefined) {
                dispatch(handleSearchParams({
                    ...searchParams, keyword: e.target.value, status: checkStatusExist?.status, page: 1,
                }));
            } else {
                dispatch(handleSearchParams({ ...searchParams, keyword: e.target.value, page: 1 }));
            }
        }
    };

    const initialState = { hiddenColumns: deletePermission === false && editPermission === false ? ['ACTION'] : [] };
    const columnData = liveCall ? liveCallData : data;
    const tableInstance = useTable({
        columns,
        data: columnData,
        initialState,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
    } = tableInstance;
    const toggleColumns = useCallback((col) => () => {
        col.toggleHidden();
        setTableColumn(allColumns);
        setTimeout(() => {
            const checkedAllVisibleOrNot = tableColumn.filter((item) => item.isVisible === true);
            const checkLength = checkedAllVisibleOrNot.length === allColumns.length;
            setAllChecked(checkLength);
        }, 100);
    });
    const toggleAllColumns = (event) => {
        getTableProps();
        allColumns.forEach((c) => {
            if (c.Header !== 'ACTIONS' && c.id !== 'name') {
                if (event.target.checked) {
                    !c.isVisible && c.toggleHidden();
                } else if (c.isVisible && c.Header !== 'ACTIONS' && c.id !== 'name') {
                    c.toggleHidden();
                }
            }
        });
        setAllChecked(!allChecked);
    };
    const handleStatusFilter = (event) => {
        let getStatus = [];
        if (event.target.checked) {
            if (searchParams?.status !== undefined && searchParams.status !== '') {
                getStatus = searchParams?.status?.split(',');
            }
            const checkStatusExistorNot = getStatus?.includes(event.target.value);
            if (!checkStatusExistorNot) {
                getStatus.push(event.target.value);
                const filterArr = { ...searchParams };
                filterArr.status = getStatus.toString();
                setStatusFilterData(getStatus.toString());
                filterArr.page = 1;
                if (filterArr) {
                    dispatch(handleSearchParams(filterArr));
                }
            }
        } else {
            if (searchParams?.status !== undefined) {
                getStatus = searchParams.status.split(',');
            }
            const checkStatusExistorNot = getStatus.includes(event.target.value);
            if (checkStatusExistorNot) {
                const alreadyAddedindex = (element) => element === event.target.value;
                const findIndex = getStatus.findIndex(alreadyAddedindex);
                if (findIndex !== -1) {
                    getStatus = getStatus.filter((item) => item !== event.target.value);
                }
                const filterArr = { ...searchParams };
                filterArr.status = getStatus.toString();
                setStatusFilterData(getStatus.toString());
                filterArr.page = 1;
                if (filterArr) {
                    dispatch(handleSearchParams(filterArr));
                }
            }
        }
    };
    const { data: Timezone } = useHandleGetApiQuery(
        {
            url: 'timezone?page=-1',
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const handleTimeZoneChange = (value) => {
        const val = value ? parseInt(value, 10) : '';
        dispatch(handleReportData({ ...reportData, timezone_id: val }));
        dispatch(handleSearchParams({
            keyword: search,
            page: 1,
            startDate: reportData.startDate,
            endDate: reportData.endDate,
            timezone_id: timeZone,
            advertiser_id: advertiserId,
        }));
        setTimeZone(val);
    };
    useEffect(() => {
        if (user?.time_zone) {
            setTimeZone(user.time_zone);
        }
    }, [user]);

    const { data: timeData } = useHandleGetApiQuery({ url: 'globals/admin_timezone' });
    useEffect(() => {
        if (userType === PLATFORM_PROVIDER) {
            setTimeZone(timeData?.data?.id);
        }
    }, [timeData]);
    return (
        <>
            {isFetching && <Loader />}
            <div className="header d-flex align-items-center justify-content-between pb-0 flex-wrap">
                <h2 className="setMmargin">{title}</h2>
                <div className="table-right-side-menu align-items-center gap-2 form-data">
                    {
                        (userType === PLATFORM_PROVIDER && advertiserSearch) && (
                            <div className="advertiser-filter">
                                <SearchableAdvertiserSelect onChange={handleAdvertiserChange} name="advertiser_id" label="advertiser" />
                            </div>
                        )
                    }
                    {/* Timezone section :: BEGIN */}
                    {
                        showTimeZone && (
                            <div className="filter-dropdown timezone-section">
                                <SearchableSelect
                                    label="Timezone"
                                    options={Timezone?.data}
                                    onChange={handleTimeZoneChange}
                                    value={timeZone}
                                    name="time_zone"
                                    optionalFieldName="zone_name"
                                />
                            </div>
                        )
                    }
                    {/* Timezone section :: END */}

                    {/* Calender section :: BEGIN */}
                    {
                        showCalander && (
                            <div className="filter-dropdown">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateRangeChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    isClearable
                                    maxDate={notRequiredMaxDateInDatePicker ? '' : currentDate}
                                    dateFormat="dd/MM/yyyy"
                                    className="themeInput datepickerrange-input"
                                    showYearDropdown
                                    showMonthDropdown
                                />
                            </div>
                        )
                    }
                    {/* Calender section :: END */}
                    {/* search section :: BEGIN */}
                    { searching && (
                        <input
                            type="text"
                            placeholder="Search"
                            className="themeInput"
                            onChange={handleChange(advertiserId)}
                            value={search}
                        />
                    )}

                    {/* search section :: END */}

                    {/* action dropdown section :: BEGIN */}
                    {columnFilter && (
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="themeInput w-40 green-btn">
                                <img src={filterIcon} alt="ICON" className="cursor-pointer" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="fancy-checkbox ml-2">
                                    <label>
                                        {/* <input {...getToggleHideAllColumnsProps()} type="checkbox" /> */}
                                        <input onClick={toggleAllColumns} type="checkbox" checked={allChecked} />
                                        <span>All</span>
                                    </label>
                                </div>

                                {allColumns.map((column) => (
                                    column.Header !== 'ACTIONS' && column.id !== 'name' ? (
                                        <div className="fancy-checkbox ml-2" key={Math.random()}>
                                            <label>
                                                {/* <input {...column.getToggleHiddenProps()} type="checkbox" /> */}
                                                <input type="checkbox" onChange={toggleColumns(column)} checked={column.isVisible} />
                                                <span className="chekbox-set"><p>{column.Header}</p></span>
                                            </label>
                                        </div>
                                    ) : null

                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {allStatusFilter?.length > 0 && (
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="themeInput w-40 green-btn">
                                <img src={filterIcon} alt="ICON" className="cursor-pointer" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {allStatusFilter.map((filter) => (
                                    <div className="fancy-checkbox ml-2">
                                        <label>
                                            <input
                                                id={filter.value}
                                                type="checkbox"
                                                value={filter.value}
                                                onChange={handleStatusFilter}
                                                checked={searchParams?.status?.split(',')?.indexOf(filter.value) !== -1}
                                            />
                                            <span className="chekbox-set" htmlFor={filter.value}><p>{filter.option}</p></span>
                                        </label>
                                    </div>

                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {(userType === ADVERTISER && importCsv && module === 'blacklist') && (
                        <button
                            className="btn btn-secondary secondary-importbtn"
                            onClick={handleImportCsv}
                            type="button"
                        >
                            Import CSV
                        </button>
                    )}
                    { (userType === ADVERTISER && createButton && createPermission) ? (
                        <button
                            className="btn btn-primary"
                            onClick={handleClick}
                            type="button"
                        >
                            {btnText}
                        </button>
                    ) : ''}
                    { (userType === PLATFORM_PROVIDER && importCsv && createPermission) ? (
                        <button
                            className="btn btn-secondary secondary-importbtn"
                            onClick={handleImportCsv}
                            type="button"
                        >
                            Import CSV
                        </button>
                    ) : ''}
                    { ((userType === ADVERTISER || userType === PLATFORM_PROVIDER) && ExportCsv) ? (
                        <button
                            className="btn btn-secondary secondary-importbtn"
                            onClick={handleExportCsv}
                            type="button"
                            disabled={columnData?.length === 0}
                        >
                            Export CSV
                        </button>
                    ) : ''}
                    { ((userType === ADVERTISER || userType === PLATFORM_PROVIDER) && ExportPdf) ? (
                        <button
                            className="btn btn-secondary secondary-importbtn export-pdf-btn"
                            onClick={handleExportPdf}
                            type="button"
                            disabled={columnData?.length === 0}
                        >
                            Export PDF
                        </button>
                    ) : ''}
                    { (userType === PLATFORM_PROVIDER && createButton) && (
                        <button
                            className="btn btn-primary"
                            onClick={handleClick}
                            type="button"
                        >
                            {btnText}
                        </button>
                    )}
                    {isRefetch && (
                        <div className="refresh-chart" onClick={handleRefetchRecord} title={'Refetch Data'}><i className="wi wi-refresh" /></div>
                    )}
                    {/* CREATE btn section :: END */}
                </div>
                {/* Filter section :: BEGIN */}
                {showCommonChipsFilter && (
                    <CommonChipsFilter
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        statusOptions={statusOptions}
                        setStatusOptions={setStatusOptions}
                        advertiserSearch={advertiserSearch}
                        componentName={componentName}
                        initialFilter={initialFilter}
                        setBlockedNumberFilter={setBlockedNumberFilter}
                    />
                )}
                {/* Filter section :: END */}
            </div>
            {isLoading ? <Loader /> : (
                <>
                    {graphshow && (
                        <div className="graph-div">
                            <div className="graph-border pb-4" />
                            <h4 className="text-uppercase text-center pt-1">{graphTitle}</h4>
                            <ReactEcharts
                                option={graphOption}
                                style={{ height: 400 }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                    )}
                    {/* Show Chart section :: BEGIN */}
                    {showChart && (
                        <div className="chart-show-section">
                            <Reportchart setTimeInterval={setTimeInterval} timeInterval={timeInterval} setStartDate={setStartDate} setEndDate={setEndDate} datechange={datechange} startDate={startDate} endDate={endDate} />
                        </div>
                    )}
                    {/* Filter table section :: BEGIN */}
                    {showfilterTable && (
                        <>
                            {/* <div className="border-set" /> */}
                            <div className={`filtertable-section ${showCommonChipsFilter ? 'pt-3' : ''}`}>
                                <div className="table-responsive filterTable-data p-0">
                                    {/*  */}
                                    {/* Show Chart section :: END */}
                                    <table className="table parentTable" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.length > 0 ? rows.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <>
                                                        <tr
                                                            aria-label="Click to view detail"
                                                            {...row.getRowProps()}
                                                            onClick={() => {
                                                                setIsLiveCallTableOpen(!isLiveCallTableOpen);
                                                                if (isLiveCallTableOpen && row.original.target_id === idObjectForAdvertiser.target_id && row.original.campaign_id === idObjectForAdvertiser.campaign_id && userType === ADVERTISER) {
                                                                    setIsLiveCallTableOpen(false);
                                                                    setIdObjectForAdvertiser({});
                                                                    if (showPagination) {
                                                                        handleFilterTablePage(1);
                                                                    }
                                                                } else if (isLiveCallTableOpen && row.original.campaign_id === idObjectForAdvertiser.campaign_id && row.original.advertiser_id === idObjectForAdvertiser.advertiser_id && userType === PLATFORM_PROVIDER) {
                                                                    setIsLiveCallTableOpen(false);
                                                                    setIdObjectForAdvertiser({});
                                                                    if (showPagination) {
                                                                        handleFilterTablePage(1);
                                                                    }
                                                                } else {
                                                                    setIsLiveCallTableOpen(true);
                                                                    setIdObjectForAdvertiser({
                                                                        campaign_id: row.original.campaign_id, target_id: row.original?.target_id, advertiser_id: row.original.advertiser_id, blacklisted_caller: blackNumberExcluded,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {row.cells.map((cell) => <td className="cursor-pointer" {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                                                        </tr>
                                                        {/* Advertiser Inner table Started */}
                                                        {/* {isLiveCallTableOpen && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.target_id === idObjectForAdvertiser?.target_id && (
                                                            <tr>
                                                                <td className="child-td" colSpan={row.cells.length}>
                                                                    <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                </td>
                                                            </tr>
                                                        )} */}
                                                        {isLiveCallTableOpen && userType === ADVERTISER && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.target_id === idObjectForAdvertiser?.target_id && (
                                                            <tr>
                                                                <td className="child-td" colSpan={row.cells.length}>
                                                                    <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {isLiveCallTableOpen && userType === PLATFORM_PROVIDER && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.advertiser_id === idObjectForAdvertiser?.advertiser_id && (
                                                            <tr>
                                                                <td className="child-td" colSpan={row.cells.length}>
                                                                    <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {/* Advertiser Inner Table Ended */}
                                                    </>
                                                );
                                            }) : (
                                                <tr>
                                                    <td className="text-center" colSpan={100}><p>No data available.</p></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Filter table section :: END */}
                    {!showfilterTable && (
                        <div className="body table-responsive">
                            {/*  */}
                            {/* Show Chart section :: END */}
                            <table className="table table-hover" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.length > 0 ? rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                                            </tr>
                                        );
                                    }) : (
                                        <tr>
                                            <td className="text-center" colSpan={100}><p>No data available.</p></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

BasicTable.propTypes = {
    title: PropTypes.string,
    handleClick: PropTypes.func,
    handlePortClick: PropTypes.func,
    btnText: PropTypes.string,
    secondBtnText: PropTypes.string,
    data: PropTypes.instanceOf(Object),
    columns: PropTypes.instanceOf(Object),
    advertiserColumns: PropTypes.instanceOf(Object),
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    createButton: PropTypes.bool,
    portButton: PropTypes.bool,
    deletePermission: PropTypes.bool,
    editPermission: PropTypes.bool,
    importCsv: PropTypes.bool,
    handleImportCsv: PropTypes.func,
    columnFilter: PropTypes.bool,
    statusFilter: PropTypes.instanceOf([object]),
    searching: PropTypes.bool,
    ExportCsv: PropTypes.bool,
    ExportPdf: PropTypes.bool,
    handleExportCsv: PropTypes.func,
    handleExportPdf: PropTypes.func,
    showCalander: PropTypes.bool,
    showTimeZone: PropTypes.bool,
    showChart: PropTypes.bool,
    showCommonChipsFilter: PropTypes.bool,
    filterOptions: PropTypes.array,
    initialFilter: PropTypes.array,
    setFilterOptions: PropTypes.func,
    statusOptions: PropTypes.array,
    setStatusOptions: PropTypes.func,
    showfilterTable: PropTypes.bool,
    componentName: PropTypes.string,
    datepickerRange: PropTypes.instanceOf([object]),
    advertiserSearch: PropTypes.bool,
    datechangeFilter: PropTypes.func,
    handleRefetchRecord: PropTypes.func,
    isRefetch: PropTypes.bool,
    liveCall: PropTypes.bool,
    notRequiredMaxDateInDatePicker: PropTypes.bool,
    setIsLiveCallTableOpen: PropTypes.func,
    isLiveCallTableOpen: PropTypes.bool,
    idObjectForAdvertiser: PropTypes.instanceOf(Object),
    setIdObjectForAdvertiser: PropTypes.func,
    individualColumns: PropTypes.instanceOf(Object),
    individualData: PropTypes.instanceOf(Object),
    dataLoader: PropTypes.bool,
    showPagination: PropTypes.bool,
    setDataLoader: PropTypes.func,
    handleFilterTablePage: PropTypes.func,
    currentFilterTablePage: PropTypes.number,
    module: PropTypes.number,
    setTimeInterval: PropTypes.func,
    timeInterval: PropTypes.number,
    searchRequired: PropTypes.bool,
    refetchDetailRecord: PropTypes.func,
    graphOption: PropTypes.instanceOf(Object),
    graphshow: PropTypes.bool,
    graphTitle: PropTypes.string,

};
BasicTable.defaultProps = {
    createButton: false,
    portButton: false,
    deletePermission: true,
    editPermission: true,
    importCsv: false,
    ExportCsv: false,
    ExportPdf: false,
    columnFilter: true,
    statusFilter: {},
    searching: true,
    showCalander: false,
    showTimeZone: false,
    advertiserSearch: false,
    componentName: '',
    isRefetch: false,
    notRequiredMaxDateInDatePicker: false,
    graphshow: false,
    graphOption: {},
    graphTitle: '',
};

export default BasicTable;
