/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CommonModdal from '../../components/Common/CommonModal';
import AlertIcon from '../../assets/images/alert-icon.svg';

const PlanStatusChangePopup = ({
    show, handleClose, handleSubmit,
    selectedPlanInfo,
}) => (
    <CommonModdal
        show={show}
        type=""
        classAddon="theme-cyan text-center footer-center"
        size="small"
        heading=""
        openSubmitModal={handleSubmit}
        handleClose={handleClose}
        footerButtons1="Yes"
        footerButtons2="No"
        isDelete
    >
        <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
        {selectedPlanInfo?.name ? (
            <p>
                Are you sure want to switch
                {' '}
                {selectedPlanInfo?.name && (
                    <b>
                        &nbsp;
                        {selectedPlanInfo?.name}
                    </b>
                )}
                {' '}
                plan status ?
            </p>
        )
            : (
                <p>
                    {' '}
                    Are you sure want to switch plan status ?
                </p>
            )}
    </CommonModdal>
);
PlanStatusChangePopup.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedPlanInfo: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};
export default PlanStatusChangePopup;
