import { baseAPIService } from '.';

const CommonGetWithPostApi = baseAPIService.injectEndpoints({
    endpoints: (build) => ({
        handleGetWithPost: build.mutation({
            query: ({ url, data }) => ({
                url,
                method: 'POST',
                body: data || null,
            }),
        }),
    }),
    overrideExisting: true,
});
// eslint-disable-next-line import/prefer-default-export
export const { useHandleGetWithPostMutation } = CommonGetWithPostApi;
