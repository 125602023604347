import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LogoIcon from '../assets/images/logo-black-icon.svg';
import LogoWhiteIcon from '../assets/images/logo-white-icon.svg';

const Loader = ({ doNotScroll }) => {
    useEffect(() => {
        if (!doNotScroll) window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="page-loader-wrapper">
                        <div className="loader">
                            <div>
                                {/* <img src={LogoIcon} width="48" height="48" alt="Atlas" /> */}
                                <img
                                    src={
                                        document.body.classList.contains('full-dark')
                                            ? LogoWhiteIcon
                                            : LogoIcon
                                    }
                                    alt="Atlas"
                                    width="48"
                                    height="48"
                                />
                            </div>
                            <p>
                                Please wait...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Loader;

Loader.propTypes = {
    doNotScroll: PropTypes.bool.isRequired,
};
