import { createSlice } from '@reduxjs/toolkit';
import { addPageLoadActivityLog } from '../../Util';

const initialState = {
    isAuthenticated: sessionStorage.getItem('token'),
    token: sessionStorage.getItem('token'),
    user: null,
    userType: sessionStorage.getItem('user_type'),
    isLogout: false,
    loginnedToken: sessionStorage.getItem('token'),
};

export const authSlice = createSlice({
    initialState,
    name: 'authSlice',
    reducers: {
        setCredentials: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = action.payload.isAuthenticated;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        Logout: (state) => {
            addPageLoadActivityLog('Logout', 'LOGOUT');
            state.token = null;
            state.user = null;
            state.isAuthenticated = false;
            sessionStorage.removeItem('tokenExpiryTime');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('loggedInTime');
            sessionStorage.removeItem('refresh_token');
            sessionStorage.removeItem('user_type');
            sessionStorage.removeItem('isRemember');
            state.loginnedToken = sessionStorage.getItem('token');
        },
        UserLogout: (state) => {
            state.isLogout = true;
        },
        setLoginnedToken: (state, action) => {
            state.loginnedToken = action.payload;
        },
    },
});

export const {
    setCredentials, setAdvertiserCredentials, Logout, setUser, setUserType, UserLogout, setLoginnedToken,
} = authSlice.actions;

export default authSlice.reducer;
