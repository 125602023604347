/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

const PaginationComponent = ({
    itemsCount,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    dataLength,
    alwaysShown = true,
}) => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;
    const changePage = (number) => {
        if (currentPage === number) return;
        setCurrentPage(number);
    };

    const onPageNumberClick = (pageNumber) => {
        changePage(pageNumber);
    };

    const onPreviousPageClick = () => {
        changePage(currentPage - 1);
    };

    const onNextPageClick = () => {
        changePage(currentPage + 1);
    };

    const setLastPageAsCurrent = () => {
        if (currentPage > pagesCount) {
            setCurrentPage(pagesCount);
        }
    };

    let isPageNumberOutOfRange;
    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2;

        if (
            isPageNumberFirst
      || isPageNumberLast
      || isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    useEffect(setLastPageAsCurrent, [pagesCount]);

    return (
        isPaginationShown && (typeof dataLength !== 'undefined' && dataLength !== 0) && (
            <Pagination className="justify-content-end">
                <span className="mr-md-3 length-data">
                    showing
                    {' '}
                    {dataLength}
                    {' '}
                    out of
                    {' '}
                    {itemsCount}
                    {' '}
                    results
                </span>
                <div className="pagignation-list">
                    <Pagination.Prev
                        onClick={onPreviousPageClick}
                        disabled={isCurrentPageFirst}
                    />
                    {pageNumbers}
                    <Pagination.Next
                        onClick={onNextPageClick}
                        disabled={isCurrentPageLast}
                    />
                </div>
            </Pagination>
        )

    );
};

PaginationComponent.propTypes = {
    itemsCount: PropTypes.number,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    itemsPerPage: PropTypes.number,
    alwaysShown: PropTypes.bool,
    dataLength: PropTypes.number,
};

export default PaginationComponent;
