/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import PropTypes from 'prop-types';

const SearchableSelect = ({
    modalEl, options, onChange, value, label, name, optionalFieldName, isDisabled, multiple, multipleValue, optionalLabel, blankOption, required, dynamicCreateNewOption,
}) => {
    const select2Ref = useRef(null);
    const selectMultiRef = useRef(null);
    const selectPlaceholder = `Select ${label}${required ? '*' : ''}`;
    useEffect(() => {
        if (optionalLabel && !modalEl) {
            $(select2Ref.current).select2({
                placeholder: selectPlaceholder,
                allowClear: true,
                tags: dynamicCreateNewOption,
            });
        } else if (modalEl) {
            $(select2Ref.current).select2({
                placeholder: selectPlaceholder,
                allowClear: true,
                tags: true,
                dropdownParent: $(select2Ref.current).closest(modalEl),
            });
        } else {
            $(select2Ref.current).select2({
                tags: dynamicCreateNewOption,
            });
        }
        $(selectMultiRef.current).select2({
            placeholder: selectPlaceholder,
            tags: dynamicCreateNewOption,
            tokenSeparators: [',', ' '],

        });
        if (!multiple) {
            $(select2Ref.current).on('change', function () {
                onChange($(this).val());
            });
        } else {
            $(selectMultiRef.current).on('change', (e) => {
                onChange(e);
            });
        }
        return () => {
            $(select2Ref.current).select2('destroy');
            $(selectMultiRef.current).select2('destroy');
        };
    }, [onChange]);
    return (
        <>
            {multiple ? (
                <select ref={selectMultiRef} className="select2 form-control" value={multipleValue} onChange={onChange} disabled={isDisabled} name={name} multiple>
                    {options?.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option[optionalFieldName]}
                        </option>
                    ))}
                </select>
            ) : (
                <select ref={select2Ref} className="select2 form-control" value={value} onChange={onChange} disabled={isDisabled} name={name}>
                    {blankOption && (
                        <option value="">{optionalLabel ? '' : `${selectPlaceholder}`}</option>
                    )}
                    {options?.map((option) => {
                        const optionLabel = optionalFieldName ? option[optionalFieldName] : option;
                        const optionValue = optionalFieldName ? option.id : option;
                        return (
                            <option key={optionValue} value={optionValue}>
                                {optionLabel}
                            </option>
                        );
                    })}
                </select>
            )}
        </>
    );
};
SearchableSelect.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    name: PropTypes.string,
    optionalFieldName: PropTypes.string,
    isDisabled: PropTypes.bool,
    multiple: PropTypes.bool,
    multipleValue: PropTypes.array,
    optionalLabel: PropTypes.bool,
    blankOption: PropTypes.bool,
    modalEl: PropTypes.string,
    dynamicCreateNewOption: PropTypes.bool,
    required: PropTypes.bool,
};
SearchableSelect.defaultProps = {
    optionalFieldName: 'name',
    isDisabled: false,
    multiple: false,
    value: '',
    optionalLabel: false,
    label: 'Carrier',
    blankOption: true,
    required: false,
    modalEl: '',
    dynamicCreateNewOption: false,
};
export default SearchableSelect;
