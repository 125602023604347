/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import PropTypes from 'prop-types';

const SearchableSelectWithApi = ({
    value, label, name, onChange, multiple, url,
}) => {
    const select2Ref = useRef(null);
    useEffect(() => {
        $(select2Ref.current).select2({
            minimumInputLength: 3,
            ajax: {
                url: process.env.REACT_APP_BASE_URL + url,
                headers: { Token: sessionStorage.getItem('token') },
                dataType: 'json',
                multiple: true,
                data(params) {
                    const query = {
                        keyword: params.term,
                    };
                    return query;
                },
                processResults(data) {
                    return {
                        results: $.map(data.data, (obj) => ({ id: obj.id, text: `${obj?.firstname} ${obj?.middlename} ${obj.lastname}` })),
                    };
                },
            },
            cache: true,
            placeholder: `Select ${label}`,
            allowClear: true,
        });
        $(select2Ref.current).on('change', (e) => {
            if (!multiple) {
                const SelectedData = $(select2Ref.current).val() ? parseInt($(select2Ref.current).val(), 10) : '';
                onChange(SelectedData);
            } else {
                onChange(e);
            }
        });
        return () => {
            $(select2Ref.current).select2('destroy');
        };
    }, [onchange, url]);
    return (
        <>
            {multiple ? (
                <select ref={select2Ref} className="select2 form-control" id="select2 form-control" onChange={onChange} name={name} value={value} multiple>
                    <option value="">{`Select ${label}`}</option>
                </select>
            ) : (
                <select ref={select2Ref} className="select2 form-control" id="select2 form-control" onChange={onChange} name={name} value={value}>
                    <option value="">{`Select ${label}`}</option>
                </select>
            )}
        </>
    );
};
SearchableSelectWithApi.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    url: PropTypes.string,

};
SearchableSelectWithApi.defaultProps = {
    multiple: false,
    url: 'platformadmin/advertisers/dropdown',
};
export default SearchableSelectWithApi;
