/* eslint-disable react/prop-types */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TooltipContainer = ({
    children, text, label, id, required,
}) => (
    <>
        <label htmlFor={id}>
            {label}
            {required && <span className="text-red">*</span>}
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={(
                    <Tooltip id="tooltip-top">
                        {text}
                    </Tooltip>
                )}
            >
                <i className="icon-question ml-1 align-middle" />

            </OverlayTrigger>
        </label>
        {children}
    </>
);

TooltipContainer.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};
TooltipContainer.defaultProps = {
    required: false,
};
export default TooltipContainer;
