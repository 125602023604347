/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAddMoneyPopup: false,
    paymentStatus: '',
    amount: 0,
    globalConfig: null,
    completed: false,
    rejected: false,
    planId: null,
    searchParams: {},
    reportData: {},
    darkOrLightMode: 'light_mode',
    currentPlan: '',
    requiredAmount: false,
    walletAmount: '',
    choosePlanId: '',
    priceModal: false,
    liveCallCount: null,
    totalCallCount: null,
    liveCallToken: null,
    homeButtonDisabled: false,
};

export const CommonSlice = createSlice({
    initialState,
    name: 'CommonSlice',
    reducers: {
        handleAddMoneyPopup: (state, action) => {
            state.isAddMoneyPopup = !action.payload ? true : !!action.payload;
        },
        handlePaymentStatus: (store, action) => {
            store.paymentStatus = action.payload;
        },
        handleAmount: (store, action) => {
            store.amount = action.payload;
        },
        handleWalletBalance: (store, action) => {
            store.walletAmount = action.payload;
        },
        handleCurrentPlan: (store, action) => {
            store.currentPlan = action.payload;
        },
        handleGlobalConfig: (store, action) => {
            store.globalConfig = action.payload;
        },
        handleComleted: (store, action) => {
            store.completed = action.payload;
        },
        handleRejected: (store, action) => {
            store.rejected = action.payload;
        },
        handleRequiredAmount: (store, action) => {
            store.requiredAmount = action.payload;
        },
        handleSearchParams: (store, action) => {
            store.searchParams = action.payload;
        },
        handleReportData: (store, action) => {
            store.reportData = action.payload;
        },
        handlePlanId: (store, action) => {
            store.planId = action.payload;
        },
        handleThemeMode: (store, action) => {
            store.darkOrLightMode = action.payload;
        },
        handleChoosePlan: (store, action) => {
            store.choosePlanId = action.payload;
        },
        handlePricingModal: (store, action) => {
            store.priceModal = action.payload;
        },
        handleCallData: (store, action) => {
            const data = action.payload;
            store.liveCallCount = data?.active_calls;
            store.totalCallCount = data?.total_calls;
            store.liveCallToken = data?.token;
        },
        handleHomeButtonDisabledInPlanPurchase: (store, action) => {
            store.homeButtonDisabled = action.payload;
        },
    },
});
export const {
    handleAddMoneyPopup, handlePaymentStatus, handleReportData,
    handleAmount, handleGlobalConfig, handleComleted, handleSearchParams, handleRejected, handlePlanId, handleThemeMode, handleCurrentPlan, handleRequiredAmount, handleWalletBalance, handleShowWelToast, handleChoosePlan, handlePricingModal, handleCallData, handleHomeButtonDisabledInPlanPurchase,
} = CommonSlice.actions;

export default CommonSlice.reducer;
