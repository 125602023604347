/* eslint-disable react/button-has-type */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import errorImage from '../assets/images/code-error.png';
import Logo from '../assets/images/logo.png';

const ChunkErrorPage = () => {
    const handleRefresh = () => {
        window.location.reload();
    };
    return (
        <div className="theme-cyan">
            <div className="page-not-found gap-3">
                <div className="image-section">
                    {/* <img alt="ErrorImage" src={errorImage} className="error-img" /> */}
                </div>
                <div className="">
                    <img
                        src={Logo}
                        alt="Lucid Logo"
                        width="150"
                        className="mb-4"
                    />
                    <h3>Ohh!!! The problem in loading.</h3>
                    <p className="width-set m-auto">There is a problem in loading the page. Please refresh the page or click on the below button.</p>
                    <p><button className="btn btn-primary mt-4" onClick={handleRefresh}>Refresh Page</button></p>
                </div>
            </div>
        </div>
    );
};
export default ChunkErrorPage;
