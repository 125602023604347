import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessageComponent = ({ touched, error }) => (
    <h6
        style={{
            fontSize: '.9em',
            color: '#FC3C3C',
            fontWeight: 400,
            transition: '.2s all',
        }}
        className={error ? 'formik-error' : ''}
    >
        {touched && error ? error : ''}
    </h6>
);
ErrorMessageComponent.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.string,
};
ErrorMessageComponent.defaultProps = {
    error: '',
    touched: false,
};

export default ErrorMessageComponent;
