import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
// import Popups from './screens/Popups';
import PermissionNotFoundPage from './Layout/RootLayout/PermissionNotFoundPage';
import PlanTable from './screens/Plan/PlanTable';
import AddPlan from './screens/Plan/AddPlan';
import WhiteListIPAddressTable from './screens/WhiteIPAddress/WhiteListIPAddressTable';
import AddWhiteIPAddress from './screens/WhiteIPAddress/AddWhiteIPAddress';
import ExpensesList from './screens/Expenses/ExpensesList';
import PaymentMethodList from './screens/Payment/PaymentMethod/PaymentMethodList';
import AddPaymentMethod from './screens/Payment/PaymentMethod/AddPaymentMethod';
import AdvertiserReport from './screens/Reports/AdvertiserReport';
import ActivityLogList from './screens/ActivityLog/ActivityLogList';
import BitcoinList from './screens/Payment/Bitcoin/BitcoinList';
import CreateProvider from './screens/Provider/CreateProvider';
import ProviderList from './screens/Provider/ProviderList';
import MaskLogin from './screens/MaskLogin';
import HelpDesk from './screens/HelpDesk/HelpDesk';
import EditDynamicContent from './screens/DynamicContent/EditDynamicContent';

const Dashboard = lazy(() => import('./screens/Dashbord/Dashbord'));
const Registration = lazy(() => import('./screens/Auth/Registration'));
const ForgotPassowrd = lazy(() => import('./screens/Auth/ForgotPassword'));
const ResetPassowrd = lazy(() => import('./screens/Auth/reset-password'));
const RootLayout = lazy(() => import('./Layout/RootLayout/RootLayout'));
const ErrorPage = lazy(() => import('./Layout/RootLayout/ErrorPage'));
const Login = lazy(() => import('./screens/Login'));
const Signup = lazy(() => import('./screens/signup'));
const Campaign = lazy(() => import('./screens/Campaign/Campaign'));
const AddCampaign = lazy(() => import('./screens/Campaign/AddCampaign'));
const AddRoute = lazy(() => import('./screens/Route/AddRoute'));
const RouteGroup = lazy(() => import('./screens/Route/Group/RouteGroup'));
const AddGroup = lazy(() => import('./screens/Route/Group/AddGroup'));
const DidNumbers = lazy(() => import('./screens/Did/DidNumbers'));
const AddNumbers = lazy(() => import('./screens/Did/AddNumbers'));
const Roles = lazy(() => import('./screens/Roles/Roles'));
const AddRoles = lazy(() => import('./screens/Roles/AddRoles'));
const TransectionHistory = lazy(() => import('./screens/Reports/TransactionHistoryReport'));
const BlackListReport = lazy(() => import('./screens/Reports/BlackListReport'));
const ImportBlackListReport = lazy(() => import('./screens/Reports/ImportCsvBlacklist'));
const CdrReport = lazy(() => import('./screens/Reports/CdrReport'));
const WalletPlan = lazy(() => import('./screens/setting/WalletPlan'));
const Loader = lazy(() => import('./screens/loader'));
const RouteTable = lazy(() => import('./screens/Route/RouteTable'));
const AddExpenses = lazy(() => import('./screens/Expenses/AddExpenses'));
const LoginPlatform = lazy(() => import('./screens/LoginPlatform'));
const PlatformAdminForgotPassword = lazy(() => import('./screens/Auth/PlatformAdminForgotPassword'));
const PlatformAdminResetPassword = lazy(() => import('./screens/Auth/PlatformAdminResetPassword'));
const GroupListing = lazy(() => import('./screens/Route/Group/GroupListing'));
const KycRequestList = lazy(() => import('./screens/KYC/KycRequest/KycRequestList'));
const NotificationList = lazy(() => import('./screens/Notification/NotificationList'));
const PromotionalPlanList = lazy(() => import('./screens/PramotionalPlan/PramotionalPlanList'));
const AddPromotionalPlan = lazy(() => import('./screens/PramotionalPlan/AddPromotionalPlan'));
const KycDocumentList = lazy(() => import('./screens/KYC/KycDocument/KycDocumentList'));
const AddKYCDocument = lazy(() => import('./screens/KYC/KycDocument/AddKycDocument'));
const AdvetiserList = lazy(() => import('./screens/Advertiser/AdvetiserList'));
const AddAdvertiser = lazy(() => import('./screens/Advertiser/AddAdvertiser'));
const ImportAdvertiser = lazy(() => import('./screens/Advertiser/ImportAdvertiser'));
const PlatformAdminDashboard = lazy(() => import('./screens/Dashbord/PlatformAdminDashboard'));
const WireTransferList = lazy(() => import('./screens/Payment/WireTransfer/WireTransferList'));
const ProfileSet = lazy(() => import('./screens/Profile/profile'));
const SIPTrunkList = lazy(() => import('./screens/SIPTrunk/SIPTrunkList'));
const CreateSIPTrunk = lazy(() => import('./screens/SIPTrunk/CreateSIPTrunk'));
const UserListing = lazy(() => import('./screens/User/UserListing'));
const GlobalConfigurationList = lazy(() => import('./screens/Global/GlobalConfiguration'));
const AddUser = lazy(() => import('./screens/User/AddUser'));
const DidInventoryList = lazy(() => import('./screens/DIDInventory/DidListing'));
const AddDidInventory = lazy(() => import('./screens/DIDInventory/AddDid'));
const ImportDidCsv = lazy(() => import('./screens/DIDInventory/ImportDidCsv'));
const TermsAndConditionList = lazy(() => import('./screens/Terms & Condition/TermsAndConditionList'));
const AddTermsAndCondition = lazy(() => import('./screens/Terms & Condition/AddTermsAndCondition'));
const CallSummaryReport = lazy(() => import('./screens/Reports/CallSummaryReport'));
const LiveCallReport = lazy(() => import('./screens/Reports/LiveCallReport'));
const ExpenseSummaryReport = lazy(() => import('./screens/Reports/ExpenseSummaryReport'));
const PageNotFound = lazy(() => import('./screens/404'));
const NetwrokIssue = lazy(() => import('./screens/noInternetConnection'));
const DynamicContentList = lazy(() => import('./screens/DynamicContent/DynamicContentList'));
const routes = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'permission-denied',
                element: <PermissionNotFoundPage />,
            },
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: '',
                children: [
                    {
                        path: 'campaign',
                        element: <Campaign />,
                        index: true,
                    },
                    {
                        path: '/campaign/add-campaign',
                        element: <AddCampaign />,
                    },
                    {
                        path: '/campaign/edit-campaign/:id',
                        element: <AddCampaign />,
                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: 'routes',
                        element: <RouteTable />,
                        index: true,
                    },
                    {
                        path: '/routes/add-route',
                        element: <AddRoute />,
                    },
                    {
                        path: '/routes/edit-route/:id',
                        element: <AddRoute />,

                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: '/group-listing',
                        element: <GroupListing />,
                        index: true,

                    },
                    {
                        path: '/group-listing/add-group',
                        element: <AddGroup />,

                    },
                    {
                        path: '/group-listing/edit-group/:id',
                        element: <AddGroup />,

                    },
                ],

            },

            {
                path: 'route-group',
                element: <RouteGroup />,

            },
            // {
            //     path: '/popups',
            //     element: <Popups />,
            // },
            {
                path: '',
                children: [
                    {
                        path: 'did-numbers',
                        element: <DidNumbers />,
                    },
                    {
                        path: '/did-numbers/add-numbers',
                        element: <AddNumbers />,
                    },
                ],

            },
            {
                path: '',
                children: [
                    {
                        path: 'roles',
                        element: <Roles />,
                    },
                    {
                        path: '/roles/add-role',
                        element: <AddRoles />,
                    },
                    {
                        path: '/roles/add-role/:id',
                        element: <AddRoles />,
                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: '/users',
                        element: <UserListing />,
                        index: true,

                    },
                    {
                        path: 'users/add-user',
                        element: <AddUser />,

                    },
                    {
                        path: '/users/edit-user/:id',
                        element: <AddUser />,

                    },
                ],

            },
            {
                path: '',
                children: [
                    {
                        path: '/expenses',
                        element: <ExpensesList />,
                        index: true,
                    },
                    {
                        path: '/expenses/add-expenses',
                        element: <AddExpenses />,
                    },
                    {
                        path: '/expenses/edit-expenses/:id',
                        element: <AddExpenses />,
                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: 'kyc-documents',
                        element: <KycDocumentList />,
                        index: true,
                    },
                    {
                        path: 'kyc-documents/add-kyc-document',
                        element: <AddKYCDocument />,
                    },
                    {
                        path: '/kyc-documents/edit-kyc-document/:id',
                        element: <AddKYCDocument />,

                    },
                    {
                        path: 'kyc-requests',
                        element: <KycRequestList />,
                        index: true,
                    },
                ],
            },
            {
                path: '/notification',
                element: <NotificationList />,
                index: true,
            },
            {
                path: '/transaction-history-report',
                element: <TransectionHistory />,
                index: true,
            },
            {
                path: '',
                children: [
                    {
                        path: 'blacklist',
                        element: <BlackListReport />,
                        index: true,
                    },
                    {
                        path: 'blacklist/import-blacklist',
                        element: <ImportBlackListReport />,
                    },
                ],
            },
            {
                path: '/advertiser-report',
                element: <AdvertiserReport />,
                index: true,
            },
            {
                path: 'loader',
                element: <Loader />,
                index: true,
            },
            {
                path: '/cdr-report',
                element: <CdrReport />,
                index: true,
            },
            {
                path: '/call-summary-report',
                element: <CallSummaryReport />,
                index: true,
            },
            {
                path: '/live-call-report',
                element: <LiveCallReport />,
                index: true,
            },
            {
                path: '/roi-report',
                element: <ExpenseSummaryReport />,
                index: true,
            },
            {
                path: '/wallet-plan',
                element: <WalletPlan />,
                index: true,
            },
            {
                path: 'activity-log',
                element: <ActivityLogList />,
                index: true,
            },
            {
                path: 'help',
                element: <HelpDesk />,
                index: true,
            },
            {
                path: 'platform-admin-dashboard',
                element: <PlatformAdminDashboard />,
                index: true,
            },
            {
                path: 'wiretransfer-list',
                element: <WireTransferList />,
                index: true,
            },
            {
                path: 'bitcoin-list',
                element: <BitcoinList />,
                index: true,
            },
            {
                path: 'profile',
                element: <ProfileSet />,
                index: true,
            },
            {
                path: 'global-configuration',
                element: <GlobalConfigurationList />,
                index: true,
            },
            {
                path: '',
                children: [
                    {
                        path: 'advertisers',
                        element: <AdvetiserList />,
                        index: true,
                    },
                    {
                        path: 'advertisers/add-advertiser',
                        element: <AddAdvertiser />,
                    },
                    {
                        path: 'advertisers/edit-advertiser/:id',
                        element: <AddAdvertiser />,
                    },
                    {
                        path: 'advertisers/import-advertiser',
                        element: <ImportAdvertiser />,
                    },
                ],
            },
            {
                path: '/provider',
                element: <ProviderList />,
                index: true,
            },
            {
                path: '/provider/add-provider',
                element: <CreateProvider />,
            },
            {
                path: '/provider/edit-provider/:id',
                element: <CreateProvider />,
            },

            {
                path: '',
                children: [
                    {
                        path: '/plans',
                        element: <PlanTable />,
                        index: true,

                    },
                    {
                        path: 'plans/add-plan',
                        element: <AddPlan />,

                    },
                    {
                        path: '/plans/edit-plan/:id',
                        element: <AddPlan />,

                    },
                ],

            },
            {
                path: '',
                children: [
                    {
                        path: 'wiretransfer-list',
                        element: <WireTransferList />,
                        index: true,
                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: '/did-inventory-list',
                        element: <DidInventoryList />,
                        index: true,

                    },
                    {
                        path: '/did-inventory/add-did',
                        element: <AddDidInventory />,

                    },
                    {
                        path: '/did-inventory/edit-did/:id',
                        element: <AddDidInventory />,

                    },
                    {
                        path: '/did-inventory/import-did',
                        element: <ImportDidCsv />,
                    },
                ],

            },
            {
                path: '',
                children: [
                    {
                        path: '/terms-condition-list',
                        element: <TermsAndConditionList />,
                        index: true,

                    },
                    {
                        path: '/terms-condition/add-terms-condition',
                        element: <AddTermsAndCondition />,

                    },
                    {
                        path: '/terms-condition/edit-terms-condition/:id',
                        element: <AddTermsAndCondition />,

                    },
                ],
            },
            {
                path: '/SIP-trunk',
                element: <SIPTrunkList />,
                index: true,
            },
            {
                path: '/SIP-trunk/add-SIP',
                element: <CreateSIPTrunk />,
            },
            {
                path: '/SIP-trunk/edit-SIP/:id',
                element: <CreateSIPTrunk />,
            },
            {
                path: '',
                children: [
                    {
                        path: '/whitelist-IP-address',
                        element: <WhiteListIPAddressTable />,
                        index: true,

                    },
                    {
                        path: 'whitelist-IP-address/add-whitelist-IP',
                        element: <AddWhiteIPAddress />,

                    },
                    {
                        path: '/whitelist-IP-address/edit-whitelist-IP/:id',
                        element: <AddWhiteIPAddress />,

                    },
                ],

            },
            {
                path: '',
                children: [
                    {
                        path: '/promotional-plan',
                        element: <PromotionalPlanList />,
                        index: true,
                    },
                    {
                        path: '/promotional-plan/add-plan',
                        element: <AddPromotionalPlan />,
                    },
                    {
                        path: '/promotional-plan/edit-plan/:id',
                        element: <AddPromotionalPlan />,
                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: '/payment-methods',
                        element: <PaymentMethodList />,
                        index: true,

                    },
                    {
                        path: '/payment-methods/add-method',
                        element: <AddPaymentMethod />,

                    },
                    {
                        path: '/payment-methods/edit-method/:id',
                        element: <AddPaymentMethod />,

                    },
                ],
            },
            {
                path: '',
                children: [
                    {
                        path: '/dynamic-contents',
                        element: <DynamicContentList />,
                        index: true,

                    },
                    {
                        path: '/dynamic-contents/add-method',
                        element: <EditDynamicContent />,

                    },
                    {
                        path: '/dynamic-contents/edit-method/:id',
                        element: <EditDynamicContent />,

                    },
                ],

            },
        ],
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/signup',
        element: <Signup />,
    },
    {
        path: '/admin',
        element: <LoginPlatform />,
    },
    {
        path: '/mask-login/:token?',
        element: <MaskLogin />,
    },
    {
        path: '/signup/:token?',
        element: <Registration />,
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassowrd />,
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassowrd />,
    },
    {
        path: '/platformadmin-forgotpassword',
        element: <PlatformAdminForgotPassword />,
    },
    {
        path: '/platformadmin-resetpassword/:token',
        element: <PlatformAdminResetPassword />,
    },
    {
        path: '/PageNotFound',
        element: <PageNotFound />,
    },
    {
        path: '/noInternet',
        element: <NetwrokIssue />,
    },
]);

export default routes;
