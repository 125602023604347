import { baseAPIService } from '.';

const CommonGetApi = baseAPIService.injectEndpoints({
    endpoints: (build) => ({
        handleGetApi: build.query({
            query: ({ url, params }) => ({
                url,
                params,
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['COMMON_GET'],
        }),
    }),
    overrideExisting: true,
});
// eslint-disable-next-line import/prefer-default-export
export const { useHandleGetApiQuery } = CommonGetApi;
