/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import TooltipContainer from '../../../components/Common/TooltipConatainer';
import SearchableSelect from '../../../components/Common/CommonSearchableSelect';
import Loader from '../../loader';
import ErrorMessageComponent from '../../../components/Common/FormikErrorMessage';
import { useHandleGetApiQuery } from '../../../app/features/api/CommonGetApi';
import CommonModdal from '../../../components/Common/CommonModal';
import { handleSearchParams } from '../../../app/features/CommonSlice';
import InputWithTooltip from '../../../components/Common/InputWithTooltip';
import {
    BITCOIN_SLUG, RAZORPAY_SLUG, WIRETRANSFER_SLUG, WIRETRANSFER_USA_SLUG,
} from '../../../Constant';
import { paymentMethodValidationSchema } from '../../../Validation';
import { useHandleUpdateOrDeleteMutation } from '../../../app/features/api/CommonChangeApi';
import { Toast } from '../../../components/Common/Toaster';

const AddPaymentMethod = () => {
    const { id } = useParams();
    const [serverData, setServerData] = useState();
    const [error, setError] = useState(false);
    const { data, isLoading: dataLoading } = useHandleGetApiQuery({ url: `/gateways/${id}` }, { skip: error });
    const [preview, setPreview] = useState(false);
    const [bitCoinPreview, setBitCoinPreview] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [image, setImage] = useState({});
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();
    const [bitconImage, setBitcoinImage] = useState({});
    const { data: countries, isLoading } = useHandleGetApiQuery({ url: 'countries?page=-1&number_of_records=10' });
    const initialValues = {
        country: [],
        name: '',
        status: true,
        logo: '',
        slug: '',
        bank_name: '',
        account_number: '',
        account_name: '',
        bank_address: '',
        ifsc_code: '',
        // routing_number: '',
        RAZORPAY_KEY_ID: '',
        RAZORPAY_KEY_SECRET: '',
        setLogo: true,
        wallet_id: '',
        qr_code_path: '',
        setBitCoin: true,
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: paymentMethodValidationSchema,
        onSubmit: async (values) => {
            console.log('submit method call on formik');
            if (formik.values?.country?.length === 0) {
                formik.setFieldError('country', 'Country selection required');
                return;
            }
            if (formik.values.slug === WIRETRANSFER_SLUG) {
                values.configuration = JSON.stringify({
                    bank_account: {
                        bank_name: formik.values.bank_name,
                        account_number: formik.values.account_number,
                        account_name: formik.values.account_name,
                        ifsc_code: formik.values.ifsc_code,
                        swift_code: formik.values.swift_code,
                        bank_address: formik.values.bank_address,
                    },
                });
            }
            if (formik.values.slug === WIRETRANSFER_USA_SLUG) {
                values.configuration = JSON.stringify({
                    bank_account: {
                        bank_name: formik.values.bank_name,
                        account_number: formik.values.account_number,
                        account_name: formik.values.account_name,
                        swift_code: formik.values.swift_code,
                        routing_number: formik.values.routing_number,
                        bank_address: formik.values.bank_address,
                    },
                });
            }
            if (formik.values.slug === RAZORPAY_SLUG) {
                values.configuration = JSON.stringify({
                    RAZORPAY_KEY_ID: formik.values.RAZORPAY_KEY_ID,
                    RAZORPAY_KEY_SECRET: formik.values.RAZORPAY_KEY_SECRET,
                });
            }
            if (formik.values.slug === BITCOIN_SLUG) {
                values.configuration = JSON.stringify({
                    wallet_id: formik.values.wallet_id,
                    // qr_code_path: formik.values.qr_code_path,
                });
            }
            const url = id ? `/gateways/${id}` : '/gateways/';
            const formData = new FormData();
            if (!formik.values.setLogo) {
                formData.append('logo', image?.file_path);
            } else {
                formData.append('logo', values.logo);
            }
            if (formik.values.slug === BITCOIN_SLUG) {
                if (!formik.values.setBitCoin) {
                    formData.append('qr_code_path', bitconImage?.file_path);
                } else {
                    formData.append('qr_code_path', formik.values.qr_code_path);
                }
            }
            formData.append('country', String(values.country));
            formData.append('configuration', values.configuration);
            formData.append('status', values.status);
            formData.append('slug', values.slug);
            formData.append('name', values.name);
            const responseData = await handleUpdateOrDelete({ url, method: 'PUT', data: formData });
            if (responseData?.data?.status === 200) {
                setError(false);
                Toast({ type: 'success', message: responseData?.data?.message });
                navigate('/payment-methods');
            } else if (responseData?.error) {
                const statusCode = responseData.error.status;
                if (statusCode === 422) {
                    const errors = responseData.error.data?.fields;
                    setError(true);
                    if (errors) {
                        Object.keys(errors).map((keyName) => formik.setFieldError('name', errors[keyName]));
                    }
                    // eslint-disable-next-line no-useless-return
                } else if (responseData?.error.data?.message) {
                    setError(true);
                    Toast({ type: 'error', message: responseData?.error.data?.message });
                }
            }
        },
        enableReinitialize: true,
    });
    useEffect(() => {
        if (data) {
            const dataArr = data?.data;
            setServerData({
                name: dataArr.name,
                slug: dataArr.slug,
                status: dataArr.status,
                bank_name: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.bank_name : '',
                account_number: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.account_number : '',
                ifsc_code: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.ifsc_code : '',
                bank_address: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.bank_address : '',
                account_name: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.account_name : '',
                swift_code: dataArr?.slug === WIRETRANSFER_SLUG || dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.swift_code : '',
                routing_number: dataArr?.slug === WIRETRANSFER_USA_SLUG ? dataArr?.configuration?.bank_account?.routing_number : '',
                RAZORPAY_KEY_ID: dataArr?.slug === RAZORPAY_SLUG ? dataArr?.configuration?.RAZORPAY_KEY_ID : '',
                RAZORPAY_KEY_SECRET: dataArr?.slug === RAZORPAY_SLUG ? dataArr?.configuration?.RAZORPAY_KEY_SECRET : '',
                setLogo: !dataArr?.logo,
                logo: dataArr?.logo,
                setBitCoin: !(dataArr?.slug === BITCOIN_SLUG && dataArr?.configuration?.qr_code_path),
                qr_code_path: dataArr?.slug === BITCOIN_SLUG ? dataArr?.qr_code_path : '',
                wallet_id: dataArr?.slug === BITCOIN_SLUG ? dataArr?.configuration?.wallet_id : '',
                country: dataArr?.country ? dataArr.country.map((item) => item.id) : [],
            });
            formik.setFieldValue('slug', data?.data.slug);
            if (data?.data?.logo) {
                const filename = data?.data?.logo_file_name.replace(/^.*[\\/]/, '');
                const filePath = data?.data?.logo_file_name;
                setImage({ name: filename, url: data?.data?.logo, file_path: filePath });
            }
            if (data?.data?.qr_code_path?.length > 0) {
                const filename = data?.data?.qr_code_file_name.replace(/^.*[\\/]/, '');
                const filePath = data?.data?.qr_code_file_name;
                setBitcoinImage({ name: filename, url: data?.data?.qr_code_path, file_path: filePath });
            }
        }
    }, [data]);
    const handleCountryChange = (e) => {
        const values = [...e.target.selectedOptions].map((opt) => parseInt(opt.value, 10));
        formik.setFieldValue('country', values);
    };
    const handleBitCoinChange = (e) => {
        if (e.target.files[0]) {
            formik.setFieldValue('setBitCoin', true);
            setBitcoinImage({ name: e.target.files[0]?.name, url: URL.createObjectURL(e.target.files[0]) });
            formik.setFieldValue('qr_code_path', e.target.files[0]);
        }
    };
    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            formik.setFieldValue('setLogo', true);
            setImage({ name: e.target.files[0]?.name, url: URL.createObjectURL(e.target.files[0]) });
            formik.setFieldValue('logo', e.target.files[0]);
        }
    };
    const handleBitCoinImageRemove = () => {
        setBitcoinImage({});
        formik.setFieldValue('qr_code_path', '');
        formik.setFieldValue('setBitCoin', true);
        document.getElementById('qr_code_path').value = '';
    };
    const handleImageRemove = () => {
        setImage({});
        formik.setFieldValue('logo', '');
        formik.setFieldValue('setLogo', true);
        document.getElementById('logo').value = '';
    };
    const handleImageShow = () => {
        setPreview(true);
    };
    const handleClosePreview = () => {
        setPreview(false);
        setBitCoinPreview(false);
    };
    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/payment-methods');
    };
    useEffect(() => {
        console.log(formik.errors);
    }, [formik.errors]);
    const handleMethodSubmit = () => {
        if (Object.keys(formik.errors).length > 0) {
            console.log('submit method call with error');
            const firstError = document.querySelector('.formik-error');
            if (firstError) {
                (firstError?.parentElement ?? firstError)?.scrollIntoView();
                formik.handleSubmit();
            }
        } else {
            console.log('submit method call without error', formik.values);
            formik.handleSubmit();
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeValue = formik.values.slug === WIRETRANSFER_USA_SLUG ? 'SWIFT Code' : 'IFSC Code';
    const codeTooltip = formik.values.slug === WIRETRANSFER_USA_SLUG ? 'Global identifier for international transfers.' : 'Input the Indian Financial System Code (IFSC) assigned to the recipient bank branch.';
    const swiftCodeValue = formik.values.slug === WIRETRANSFER_USA_SLUG ? 'Routing Number' : 'SWIFT Code';
    const swiftCodeTooltip = formik.values.slug === WIRETRANSFER_USA_SLUG ? 'Locates your bank branch within the domestic network.' : 'Global identifier for international transfers.';

    return (
        <div className="container-fluid">
            {(dataLoading || isLoading) && <Loader />}
            <PageHeader
                HeaderText="Payment"
                Breadcrumb={[
                    { name: 'Payment', navigate: '' },
                    { name: 'Payment Method', navigate: 'payment-list' },
                    { name: 'Edit Payment Method', navigate: '' },
                ]}
            />
            <CommonModdal
                show={preview}
                handleClose={handleClosePreview}
                heading
                type="priceModal"
            >
                { image?.url && (
                    <div style={{
                        width: '400px', maxHeight: '450px', margin: 'auto', textAlign: 'center',
                    }}
                    >
                        <img className="img-fluid" src={image?.url} alt="preview-logo" />
                    </div>
                )}
            </CommonModdal>
            <CommonModdal
                show={bitCoinPreview}
                handleClose={handleClosePreview}
                heading
                type="priceModal"
            >
                { bitconImage?.url && (
                    <div style={{
                        width: '400px', maxHeight: '450px', margin: 'auto', textAlign: 'center',
                    }}
                    >
                        <img className="img-fluid" src={bitconImage?.url} alt="preview-bitcoin" />
                    </div>
                )}
            </CommonModdal>
            <div className="col-12 p-0">
                <div className="card themeTabs body">
                    <div className="card-tab-header">
                        <ul className="nav nav-tabs-new">
                            <li className="nav-item mr-1 active">
                                <div className="nav-link active">
                                    Edit Details
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <InputWithTooltip
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        label="Payment Gateway Name"
                                        text="Enter the name of the payment gateway you want to integrate"
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.name} touched={formik.touched.name} />
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <TooltipContainer label="Payment Gateway Logo" id="payment_gateway" text="Upload the logo of the selected payment gateway" required>
                                        <div className="form-group mb-2 upload-preview-section">
                                            <label htmlFor="logo" className="form-control">
                                                {image?.name}
                                            </label>
                                            <div className="d-flex align-items-start gap-1">
                                                <div className="form-group position-relative">
                                                    <input
                                                        id="logo"
                                                        name="logo"
                                                        className="upload-payment-logo form-control"
                                                        type="file"
                                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                    <button
                                                        className="btn btn-info"
                                                        type="button"
                                                        htmlFor="upl"
                                                    >
                                                        Upload
                                                    </button>
                                                </div>
                                                <div className="d-flex align-items-start gap-1">
                                                    <button className="btn btn-info ms-2 preview-btn" type="button" htmlFor="upl" onClick={handleImageShow} disabled={!image.url}>Preview</button>
                                                    {image?.url && (
                                                        <i
                                                            className="icon-close btn btn-danger ml-2 set-close-btn"
                                                            role="presentation"
                                                            onClick={handleImageRemove}
                                                            title="Remove Logo"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="small-text">File size must be less than 5MB.</p>
                                        <ErrorMessageComponent error={formik.errors.logo} touched={formik.touched.logo} />
                                    </TooltipContainer>
                                </div>
                            </div>
                            <div className="row mb-4 mt-3">
                                <div className="col-12">
                                    {/*  Note:
                                        ========================
                                        Here field changes according payment method selection
                                    */}
                                    {/* WireTransfer account details :: BEGIN */}
                                    {(formik.values.slug === WIRETRANSFER_SLUG || formik.values.slug === WIRETRANSFER_USA_SLUG) && (
                                        <div className="payment-method-box">
                                            <h6 className="mb-4">REQUIRED ACCOUNT INFORMATION</h6>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="bank_name"
                                                        value={formik.values.bank_name}
                                                        onChange={formik.handleChange}
                                                        label="Bank Name"
                                                        text=" Enter the full name of the bank where the wire transfer will be initiated"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.bank_name} touched={formik.touched.bank_name} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="account_number"
                                                        value={formik.values.account_number}
                                                        onChange={formik.handleChange}
                                                        label="Account Number"
                                                        text="Provide the unique account number associated with the recipient"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.account_number} touched={formik.touched.account_number} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="account_name"
                                                        value={formik.values.account_name}
                                                        onChange={formik.handleChange}
                                                        label="Account Holder Name"
                                                        text="Enter the full legal name of the account holder"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.account_name} touched={formik.touched.account_name} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="bank_address"
                                                        value={formik.values.bank_address}
                                                        onChange={formik.handleChange}
                                                        label="Bank Address"
                                                        text="Specify the complete address of the bank, including street address, city, state, and country"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.bank_address} touched={formik.touched.bank_address} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name={formik.values.slug === WIRETRANSFER_USA_SLUG ? 'swift_code' : 'ifsc_code'}
                                                        value={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.values.swift_code : formik.values.ifsc_code}
                                                        onChange={formik.handleChange}
                                                        label={codeValue}
                                                        text={codeTooltip}
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.errors.swift_code : formik.errors.ifsc_code} touched={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.touched.swift_code : formik.touched.ifsc_code} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name={formik.values.slug === WIRETRANSFER_USA_SLUG ? 'routing_number' : 'swift_code'}
                                                        value={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.values.routing_number : formik.values.swift_code}
                                                        onChange={formik.handleChange}
                                                        label={swiftCodeValue}
                                                        text={swiftCodeTooltip}
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.errors.routing_number : formik.errors.swift_code} touched={formik.values.slug === WIRETRANSFER_USA_SLUG ? formik.touched.routing_number : formik.touched.swift_code} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* WireTransfer account details :: END */}
                                    {/* Razor Pay account details :: BEGIN */}
                                    {formik.values.slug === RAZORPAY_SLUG && (
                                        <div className="payment-method-box">
                                            <h6 className="mb-4">REQUIRED ACCOUNT INFORMATION</h6>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="RAZORPAY_KEY_ID"
                                                        value={formik.values.RAZORPAY_KEY_ID}
                                                        onChange={formik.handleChange}
                                                        label="Razorpay Key Id"
                                                        text="Enter the unique Key Id provided by Razorpay"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.RAZORPAY_KEY_ID} touched={formik.touched.RAZORPAY_KEY_ID} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <InputWithTooltip
                                                        name="RAZORPAY_KEY_SECRET"
                                                        value={formik.values.RAZORPAY_KEY_SECRET}
                                                        onChange={formik.handleChange}
                                                        label="Razorpay Key Secret"
                                                        text="Provide the confidential Key Secret associated with your Razorpay account"
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.RAZORPAY_KEY_SECRET} touched={formik.touched.RAZORPAY_KEY_SECRET} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Razor Pay account details :: END */}
                                    {/* Bitcoin account details :: BEGIN */}
                                    {formik.values.slug === BITCOIN_SLUG && (
                                        <div className="payment-method-box">
                                            <h6 className="mb-4">REQUIRED ACCOUNT INFORMATION</h6>
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    <InputWithTooltip
                                                        name="wallet_id"
                                                        value={formik.values.wallet_id}
                                                        onChange={formik.handleChange}
                                                        label="Wallet ID"
                                                        text="Provide the unique identifier associated with the user's wallet for this payment method."
                                                        required
                                                    />
                                                    <ErrorMessageComponent error={formik.errors.wallet_id} touched={formik.touched.wallet_id} />
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    <TooltipContainer label="QR Code Image" id="qr_code" text="Upload the QR code image associated with the payment method" required>
                                                        <div className="form-group mb-2 upload-preview-section">
                                                            <label htmlFor="qr_code_path" className="form-control" title={bitconImage?.name}>
                                                                {bitconImage?.name}
                                                            </label>
                                                            <div className="d-flex align-items-start gap-1">
                                                                <div className="form-group position-relative">
                                                                    <input
                                                                        id="qr_code_path"
                                                                        name="qr_code_path"
                                                                        className="upload-payment-logo form-control"
                                                                        type="file"
                                                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                                                        onChange={handleBitCoinChange}
                                                                    />
                                                                    <button
                                                                        className="btn btn-info"
                                                                        type="button"
                                                                        htmlFor="upl"
                                                                    >
                                                                        Upload
                                                                    </button>
                                                                </div>
                                                                <div className="d-flex align-items-start gap-1">
                                                                    <button
                                                                        className="btn btn-info ms-2 preview-btn"
                                                                        type="button"
                                                                        htmlFor="upl"
                                                                        onClick={() => {
                                                                            setBitCoinPreview(true);
                                                                        }}
                                                                        disabled={!bitconImage?.url}
                                                                    >
                                                                        Preview

                                                                    </button>
                                                                    {bitconImage?.url && (
                                                                        <i
                                                                            className="icon-close btn btn-danger ml-2 set-close-btn"
                                                                            onClick={handleBitCoinImageRemove}
                                                                            title="Remove QR Code"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TooltipContainer>
                                                    <p className="small-text">File size must be less than 5MB.</p>
                                                    <ErrorMessageComponent error={formik.errors.qr_code_path} touched={formik.touched.qr_code_path} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Bitcoin Pay account details :: END */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-6 form-data promotional-plan select-error-set">
                                    <TooltipContainer label="Available Country" id="available_country" text="Specify the countries where this payment method is available for use" required>
                                        <SearchableSelect
                                            label="Country"
                                            options={countries?.data}
                                            onChange={handleCountryChange}
                                            multipleValue={formik.values.country}
                                            name="country[]"
                                            multiple
                                        />
                                    </TooltipContainer>

                                </div>
                                <div className="mt-3">
                                    <ErrorMessageComponent error={formik.errors.country} touched={formik.touched.country} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button className="btn btn-primary" type="button" onClick={handleMethodSubmit}>{id ? 'Update' : 'Create'}</button>
                                    <button className="btn btn-secondary ml-3" type="button" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPaymentMethod;
