/* eslint-disable no-restricted-globals */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import InputWithTooltip from '../../components/Common/InputWithTooltip';
import SearchableSelect from '../../components/Common/CommonSearchableSelect';
import withPostContainer from '../../Container/withPostContainer';
import { providerSchema } from '../../Validation';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import TooltipContainer from '../../components/Common/TooltipConatainer';
import { getModulePermission } from '../../Util';
import Loader from '../loader';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';

const CreateProvider = ({
    handlePostRequest,
    id, data, dataLoading,
}) => {
    if (id) {
        getModulePermission('update', 'page');
    } else {
        getModulePermission('create', 'page');
    }
    const dispatch = useDispatch();
    const [serverData, setServerData] = useState();
    const { isLoading } = useHandleGetApiQuery({ url: 'provider' });
    const navigate = useNavigate();
    const { data: Carrier } = useHandleGetApiQuery(
        {
            url: 'carriers/default',
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const initialValues = {
        name: '',
        status: 'inactive',
        connection_id: '',
        token: '',
        carrier_id: null,
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: providerSchema,
        onSubmit: (values, { setSubmitting }) => {
            const dataValue = {
                name: values.name,
                status: values.status,
                data: { token: values.token, connection_id: values.connection_id },
                carrier_id: Number(values.carrier_id),
            };
            const url = id ? `provider/${id}` : 'provider';
            handlePostRequest({
                data: dataValue,
                redirect: '/provider',
                isUpdate: !!id,
                url,
                setSubmitting,
                formik,
            });
        },
        enableReinitialize: true,
    });
    const handleStatus = useCallback(async (e) => {
        formik.setFieldValue('status', e.target.checked ? 'active' : 'inactive');
    }, []);
    const handleProviderSubmit = () => {
        if (Object.keys(formik.errors).length > 0) {
            const firstError = document.querySelector('.formik-error');
            if (firstError) {
                (firstError?.parentElement ?? firstError)?.scrollIntoView();
                formik.handleSubmit();
            }
        } else {
            formik.handleSubmit();
        }
    };
    const handleCarrierChange = (value) => {
        const selectedCarrierId = value ? parseInt(value, 10) : '';
        formik.setFieldValue('carrier_id', selectedCarrierId);
    };
    useEffect(() => {
        if (data) {
            const dataValues = data?.data;
            setServerData({
                name: dataValues?.name,
                status: dataValues?.status,
                token: dataValues?.data?.token,
                connection_id: dataValues?.data?.connection_id,
                carrier_id: dataValues?.carrier_id,
            });
        }
    }, [data]);
    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/provider');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            {(dataLoading || isLoading) && <Loader />}
            <PageHeader
                HeaderText="Provider"
                Breadcrumb={[
                    { name: 'Provider', navigate: 'provider' },
                    { name: id ? 'Edit Provider' : 'Create Provider', navigate: id ? `provider/edit-SIP/${id}` : 'provider/add-SIP' },
                ]}
            />
            <div className="col-12 p-0">
                <div className="card themeTabs body">
                    <div className="card-tab-header">
                        <ul className="nav nav-tabs-new">
                            <li className="nav-item mr-1 active">
                                <div className="nav-link active">
                                    {id ? 'Edit Details' : 'Add Details' }
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3 form-data select-error-set">
                                    <TooltipContainer label="Select Provider" id="Carrier" text="Choose a provider based on compatibility, and cost-effectiveness." required>
                                        <SearchableSelect
                                            label="Carrier"
                                            options={Carrier?.data}
                                            onChange={handleCarrierChange}
                                            value={formik?.values?.carrier_id}
                                            name="carrier_id"
                                            optionalLabel
                                        />
                                    </TooltipContainer>
                                    <ErrorMessageComponent error={formik.errors.carrier_id} touched={formik.touched.carrier_id} />
                                </div>
                                <div className="col-12 col-md-6 mb-3 select-error-set">
                                    <InputWithTooltip
                                        name="token"
                                        value={formik.values.token}
                                        onChange={formik.handleChange}
                                        label="Token"
                                        text="Implement secure token management with encryption, expiration policies, scope-based permissions, and monitoring for suspicious activity."
                                        placeholder="Token"
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.token} touched={formik.touched.token} />
                                </div>
                                <div className="col-12 col-md-6 mb-3 select-error-set">
                                    <InputWithTooltip
                                        name="connection_id"
                                        value={formik.values.connection_id}
                                        onChange={formik.handleChange}
                                        label="Connection ID"
                                        text="Use unique connection IDs to identify and manage connections between module and external services "
                                        placeholder="Connection ID"
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.connection_id} touched={formik.touched.connection_id} />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <TooltipContainer label="Status" id="status" text="Maintain status indicators to track the operational state of the provider module, ensuring it functions correctly." />
                                    <div>
                                        <label id="switch" className="switch ms-0" htmlFor="status">
                                            <input
                                                type="checkbox"
                                                id="status"
                                                onChange={handleStatus}
                                                checked={formik.values.status === 'active'}
                                                name="status"
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <ErrorMessageComponent error={formik.errors.status} touched={formik.touched.status} />
                                </div>
                                <div className="col-12 col-md-6 mb-3 select-error-set">
                                    <InputWithTooltip
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        label="Account Name"
                                        text="Include account name information to associate specific configurations with corresponding user accounts"
                                        placeholder="Account Name"
                                        required
                                    />
                                    <ErrorMessageComponent error={formik.errors.name} touched={formik.touched.name} />
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" type="button" onClick={handleProviderSubmit}>{` ${id ? 'Update' : 'Create'}`}</button>
                                    <button className="btn btn-secondary ml-3" type="button" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
CreateProvider.propTypes = {
    handlePostRequest: PropTypes.func.isRequired,
    dataLoading: PropTypes.bool.isRequired,
};

export default withPostContainer(CreateProvider, 'provider/');
