import { createSlice } from '@reduxjs/toolkit';

let toggle = false;
const initialState = {
    welcomeMessageToast: false,
    isToastMessage: false,
    addClassactive: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChild: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildApp: [false, false, false, false, false, false],
    addClassactiveChildFM: [false, false, false, false, false],
    addClassactiveChildBlog: [false, false, false, false, false],
    addClassactiveChildUI: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildWidgets: [false, false, false, false, false, false, false],
    addClassactiveChildAuth: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildPages: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildForms: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildTables: [false, false, false, false, false, false, false],
    addClassactiveChildChart: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ],
    addClassactiveChildMaps: [false, false, false],
    themeColor: 'theme-cyan', // 'theme-cyan layout-fullwidth'
    generalSetting: [false, false, true, true, false, false],
    toggleNotification: false,
    toggleEqualizer: false,
    menuProfileDropdown: false,
    sideMenuTab: [true, false, false, false],
    menuArrowToggle: false,
    menus: {},
    modulePermissionArr: {},
};

export const NavigationSlice = createSlice({
    initialState,
    name: 'navigationSlice',
    reducers: {
        onPressDashbord: (state, action) => {
            const addClass = [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ];
            addClass[action.payload] = !state.addClassactive[action.payload];
            state.addClassactive = [...addClass];
        },
        // eslint-disable-next-line consistent-return
        onPressDashbordChild: (state, action) => {
            switch (action.payload.perent) {
            case 0: {
                const addClassChild = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChild[action.payload.index] = true;
                state.addClassactiveChild = [...addClassChild];
                break;
            }
            case 1: {
                const addClassChildApp = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildApp[action.payload.index] = true;
                state.addClassactiveChildApp = [...addClassChildApp];
                break;
            }

            case 2: {
                const addClassChildFM = [false, false, false, false, false];
                addClassChildFM[action.payload.index] = true;
                state.addClassactiveChildFM = [...addClassChildFM];
                break;
            }

            case 3: {
                const addClassChildBlog = [false, false, false, false, false];
                addClassChildBlog[action.payload.index] = true;
                state.addClassactiveChildBlog = [...addClassChildBlog];
                break;
            }

            case 4: {
                const addClassChildUI = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildUI[action.payload.index] = true;
                state.addClassactiveChildUI = [...addClassChildUI];
                break;
            }

            case 5: {
                const addClassChildWdg = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildWdg[action.payload.index] = true;
                state.addClassactiveChildWidgets = [...addClassChildWdg];
                break;
            }

            case 6: {
                const addClassChildAuth = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildAuth[action.payload.index] = true;
                state.addClassactiveChildAuth = [...addClassChildAuth];
                break;
            }

            case 7: {
                const addClassChildPages = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildPages[action.payload.index] = true;
                state.addClassactiveChildPages = [...addClassChildPages];
                break;
            }

            case 8: {
                const addClassChildForms = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildForms[action.payload.index] = true;
                state.addClassactiveChildForms = [...addClassChildForms];
                state.toggleEqualizer = false;
                break;
            }

            case 9: {
                const addClassChildTables = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildTables[action.payload.index] = true;
                state.addClassactiveChildTables = [...addClassChildTables];
                state.toggleEqualizer = false;
                break;
            }

            case 10: {
                const addClassChildChart = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ];
                addClassChildChart[action.payload.index] = true;
                state.addClassactiveChildChart = [...addClassChildChart];
                state.toggleEqualizer = false;
                break;
            }

            case 11: {
                const addClassChildMaps = [false, false, false];
                addClassChildMaps[action.payload.index] = true;
                state.addClassactiveChildMaps = [...addClassChildMaps];
                state.toggleEqualizer = false;
                break;
            }
            default:
                return state;
            }
        },
        onPressThemeColor: (state, action) => {
            state.themeColor = action.payload;
            state.toggleEqualizer = false;
        },
        onPressGeneralSetting: (state, action) => {
            state.generalSetting = !state.generalSetting[action.payload];
            state.menuProfileDropdown = false;
            state.toggleEqualizer = false;
            state.toggleNotification = false;
        },
        onPressNotification: (state) => {
            state.toggleNotification = !state.toggleNotification;
            state.menuProfileDropdown = false;
            state.toggleEqualizer = false;
        },
        onPressEqualizer: (state) => {
            state.toggleNotification = false;
            state.menuProfileDropdown = false;
            state.toggleEqualizer = !state.toggleEqualizer;
        },
        onPressSideMenuToggle: (state) => {
            toggle = !toggle;
            if (toggle) {
                document.body.classList.add('offcanvas-active');
            } else {
                document.body.classList.remove('offcanvas-active');
            }
            state.menuProfileDropdown = false;
            state.toggleEqualizer = false;
            state.toggleNotification = false;
        },
        onPressMenuProfileDropdown: (state) => {
            state.menuProfileDropdown = !state.menuProfileDropdown;
            state.toggleEqualizer = false;
            state.toggleNotification = false;
        },
        onPressSideMenuTab: (state, action) => {
            const menuTab = [false, false, false, false];
            menuTab[action.payload] = true;
            state.sideMenuTab = [...menuTab];
            state.menuProfileDropdown = false;
            state.toggleEqualizer = false;
            state.toggleNotification = false;
        },
        toggleMenuArrow: (state) => {
            if (state.menuArrowToggle) {
                document.body.classList.add('layout-fullwidth');
            } else {
                document.body.classList.remove('layout-fullwidth');
            }
            state.menuArrowToggle = !state.menuArrowToggle;
            state.menuProfileDropdown = false;
            state.toggleEqualizer = false;
            state.toggleNotification = false;
        },
        handleShowWelToast: (store, action) => {
            store.welcomeMessageToast = action.payload;
        },
        tostMessageLoad: (state) => {
            state.isToastMessage = state.welcomeMessageToast && !state.isToastMessage;
        },
        modulePermission: (state, action) => {
            state.modulePermissionArr = action?.payload;
        },
    },
});

export const {
    onPressDashbord,
    onPressDashbordChild,
    onPressThemeColor,
    onPressGeneralSetting,
    onPressNotification,
    onPressEqualizer,
    onPressSideMenuToggle,
    onPressMenuProfileDropdown,
    onPressSideMenuTab,
    tostMessageLoad,
    modulePermission,
    handleShowWelToast,
} = NavigationSlice.actions;

export default NavigationSlice.reducer;
