/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import {
    visitorsSparcalOption,
    visitsSparcalOption,
} from '../Data/DashbordData';

const PageHeader = ({ HeaderText, Breadcrumb }) => {
    const [toggleMenu, setToggleMenu] = useState(false);

    const chartPlace = () => {
        const chartDom = document.getElementById('visitorsSparcalChart');
        const visitorsSparcalChart = echarts.init(chartDom);
        const option = visitorsSparcalOption;
        if (option) {
            visitorsSparcalChart.setOption(option);
        }
    };

    const chartPlace1 = () => {
        const chartDom = document.getElementById('visitsSparcalChart');
        const visitsSparcalChart = echarts.init(chartDom);
        const option = visitsSparcalOption;
        if (option) {
            visitsSparcalChart.setOption(option);
        }
    };

    useEffect(() => {
        chartPlace();
        chartPlace1();
    }, []);

    const onToggleMenu = async () => {
        setToggleMenu(!toggleMenu);
        if (!toggleMenu) {
            document.body.classList.remove('layout-fullwidth');
        } else {
            document.body.classList.add('layout-fullwidth');
        }
    };
    return (
        <div className="block-header">
            <div className="row">
                <div className="col-12">
                    <h2>
                        <a
                            href="#!"
                            className="btn btn-xs btn-link btn-toggle-fullwidth"
                            onClick={(e) => {
                                e.preventDefault();
                                onToggleMenu();
                            }}
                        >
                            {/* <img src={sidebarToggleIcon} alt="ICON" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="29" viewBox="0 0 15 29">
                                <path d="M14 1L0.999999 14.5L14 28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                        {' '}
                        {HeaderText}
                    </h2>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="dashboard">
                                <i className="icon-home" />
                            </a>
                        </li>
                        {Breadcrumb.map((item, index) => (
                            <li
                                // eslint-disable-next-line react/no-array-index-key
                                key={item.name + index}
                                className="breadcrumb-item active"
                            >
                                <a href={item.navigate ? item.navigate : null}>
                                    {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-lg-7 col-md-4 col-sm-12 text-right d-none">
                    <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
                        <div
                            id="visitorsSparcalChart"
                            className="sparkline text-left"
                            style={{ width: '12vh', height: 25 }}
                        />
                        <span>Visitors</span>
                    </div>
                    <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
                        <div
                            id="visitsSparcalChart"
                            className="sparkline text-left"
                            style={{ width: '12vh', height: 25 }}
                        />
                        <span>Visits</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

PageHeader.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    HeaderText: PropTypes.string.isRequired,
    Breadcrumb: PropTypes.array.isRequired,
};
export default PageHeader;
