/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { fullName } from '../../../Util';

const ScriptPortal = ({ isAuthenticated }) => {
    const { user } = useSelector((store) => store.auth);
    useEffect(() => {
        const chatElement = document.querySelector('div[data-id="zsalesiq"]');
        const chatbotElement = document.getElementById('chatbot');
        const script = document.createElement('script');
        if (isAuthenticated && user) {
            script.setAttribute('id', 'zsiqchat');
            script.setAttribute('type', 'text/javascript');
            // eslint-disable-next-line max-len
            script.innerHTML = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq64bc0865c68e4aea057cc7a68d5e9136b2913f37efd4104c1c0d261f1d09bee2", values:{},ready:function(){
                if ($zoho.salesiq) {
                    $zoho.salesiq.visitor.name("${fullName(user)}");
                    $zoho.salesiq.visitor.email("${user?.email}");
                }
            }};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);"<div></div>"`;
            if (chatElement) {
                chatElement.classList.remove('d-none');
            }
        } else if (chatElement) {
            script.innerHTML = '';
            chatElement.classList.add('d-none');
        }
        chatbotElement.appendChild(script);
        return () => {
            chatbotElement.removeChild(script);
        };
    }, [isAuthenticated, user]);

    return null;
};

ScriptPortal.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

const CommonChatBot = ({ isAuthenticated }) => (
    createPortal(<ScriptPortal isAuthenticated={Boolean(isAuthenticated)} />, document.getElementById('chatbot'))
);

export default CommonChatBot;
