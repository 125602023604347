/* eslint-disable no-return-assign */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/scss/bootstrap.scss';
import './assets/assets/scss/main.scss';
import './assets/assets/scss/color_skins.scss';
import 'font-awesome/scss/font-awesome.scss';

import store from './app/store';
import Toaster from './components/Common/Toaster';
import Loader from './screens/loader';
import NetworkError from './NetworkError';

const googleClientTd = await fetch(`${process.env.REACT_APP_BASE_URL}globals/google_client_id`, {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}).then(async (res) => res.json())
// eslint-disable-next-line arrow-body-style
    .then((data) => {
        return data?.data?.configuration_data?.google_auth_token;
    });
ReactDOM.createRoot(document.getElementById('root')).render(
    <Suspense fallback={<Loader />}>
        <GoogleOAuthProvider clientId={googleClientTd}>
            <Provider store={store}>
                <Toaster />
                <NetworkError />
                <App />
            </Provider>
        </GoogleOAuthProvider>
    </Suspense>,
);
serviceWorker.unregister();
