/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import { createPortal } from 'react-dom';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const CommonModdal = ({
    show, children, handleClose, heading, openSubmitModal, size, justifyContent,
    type, footerButtons1, footerButtons2, classAddon, isDelete, isButton, csvData, csvHeaders, fileName, required,
}) => {
    if (show) {
        if (type === 'priceModal' || type === 'common') {
            return createPortal(
                <Modal show={show} size={size} onHide={handleClose} className="theme-cyan" backdrop="static">
                    {heading ? (
                        <Modal.Header className="pt-1 pb-0" closeButton={type === 'priceModal'}>
                            <Modal.Title className="mb-0">{heading}</Modal.Title>
                        </Modal.Header>
                    ) : null}
                    <Modal.Body className="pt-0">{children}</Modal.Body>
                </Modal>,
                document.getElementById('portal-root'),
            );
        }
        if (type === 'imgModal') {
            return createPortal(
                <Modal show={show} size={size} onHide={handleClose} className={classAddon} backdrop="static">
                    <Modal.Header className="pt-1 pb-0" closeButton={isButton}>
                        <Modal.Title className="mb-0">{heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0 text-center">{children}</Modal.Body>
                    {footerButtons1 ? (
                        <Modal.Footer className="justify-content-center">
                            <Button variant="info" onClick={handleClose}>
                                {footerButtons1}
                            </Button>
                        </Modal.Footer>
                    ) : null}
                </Modal>,
                document.getElementById('portal-root'),
            );
        }
        if (type === 'closeOpt') {
            return createPortal(
                <Modal show={show} size={size} onHide={handleClose} className={classAddon} backdrop="static">
                    <Modal.Header closeButton={!isDelete}>
                        <Modal.Title>{heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer>
                        {footerButtons1 && (
                            <Button variant="primary" onClick={openSubmitModal}>
                                {/* Submit */}
                                {footerButtons1}
                            </Button>
                        )}
                        {footerButtons2 && (
                            <Button variant="secondary" onClick={handleClose}>
                                {/* Close */}
                                {footerButtons2}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>,
                document.getElementById('portal-root'),
            );
        }
        if (type === '') {
            return createPortal(
                <Modal show={show} size={size} onHide={handleClose} className={classAddon} backdrop="static">
                    <Modal.Header>
                        <Modal.Title>
                            {heading}
                            {required && <span className="text-red">*</span>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer className={`${justifyContent}` || 'justify-content-center'}>
                        {footerButtons1 && (
                            <Button variant="info" onClick={openSubmitModal}>
                                {footerButtons1}
                            </Button>
                        )}
                        {footerButtons2 && (
                            <Button variant="secondary" onClick={handleClose}>
                                {/* Close */}
                                {footerButtons2}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>,
                document.getElementById('portal-root'),
            );
        }
        if (type === 'exportCsvModal') {
            return createPortal(
                <Modal show={show} size={size} onHide={handleClose} className={classAddon} backdrop="static">
                    <Modal.Header closeButton={!isDelete}>
                        <Modal.Title>{heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer>
                        {footerButtons1 && (
                            <CSVLink data={csvData} headers={csvHeaders} filename={fileName}>
                                <Button variant="info">
                                    {footerButtons1}
                                </Button>
                            </CSVLink>
                        )}
                        {footerButtons2 && (
                            <Button variant="secondary" onClick={handleClose}>
                                {/* Close */}
                                {footerButtons2}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>,
                document.getElementById('portal-root'),
            );
        }
    }
    return null;
};
CommonModdal.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.node,
    handleClose: PropTypes.func,
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    size: PropTypes.string,
    classAddon: PropTypes.string,
    footerButtons1: PropTypes.string,
    footerButtons2: PropTypes.string,
    type: PropTypes.string,
    openSubmitModal: PropTypes.func,
    isDelete: PropTypes.bool,
    isButton: PropTypes.bool,
    csvHeaders: PropTypes.instanceOf(Object),
    csvData: PropTypes.instanceOf(Object),
    fileName: PropTypes.string,
    justifyContent: PropTypes.string,
    required: PropTypes.bool,
};
CommonModdal.defaultProps = {
    isDelete: false,
    isButton: true,
    required: false,
    csvHeaders: [],
    csvData: [],
    fileName: '',
};
export default CommonModdal;
