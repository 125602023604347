/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import 'echarts-gl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHandleGetWithPostMutation } from '../app/features/api/CommonGetWithPostApi';
import { handleReportData } from '../app/features/CommonSlice';

const Reportchart = ({
    setTimeInterval,
}) => {
    const dispatch = useDispatch();
    const { reportData } = useSelector((store) => store.common);
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    const [chartData, setChartData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [radioStatus, setRadioStatus] = React.useState(1);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // set radio status
    const radioHandler = (status) => {
        setRadioStatus(status);
    };
    const activeTabRef = useRef(null);
    // Tab section Script
    const onTabChange = (e) => {
        setTimeInterval(e);
        activeTabRef.current = e;
        const tab1 = document.getElementById('bacicTab-1');
        tab1.classList.remove('active');
        tab1.children[0].classList.remove('active');
        const tab2 = document.getElementById('bacicTab-2');
        tab2.classList.remove('active');
        tab2.children[0].classList.remove('active');
        const tab3 = document.getElementById('bacicTab-3');
        tab3.classList.remove('active');
        tab3.children[0].classList.remove('active');
        const tab4 = document.getElementById('bacicTab-4');
        tab4.classList.remove('active');
        tab4.children[0].classList.remove('active');
        const actab = document.getElementById(`bacicTab-${e}`);
        actab.classList.add('active');
        actab.children[0].classList.add('active');

        const tabpan1 = document.getElementById('bacicTabpan-1');
        tabpan1.classList.remove('active');
        const tabpan2 = document.getElementById('bacicTabpan-2');
        tabpan2.classList.remove('active');
        const tabpan3 = document.getElementById('bacicTabpan-3');
        tabpan3.classList.remove('active');
        const tabpan4 = document.getElementById('bacicTabpan-4');
        tabpan4.classList.remove('active');
        const actabpab = document.getElementById(`bacicTabpan-${e}`);
        actabpab.classList.add('active');
    };
    const barChartApi = async (chart = 'daily') => {
        let response;
        const controller = new AbortController();
        const { signal } = controller;
        try {
            if (activeTabRef.current === 1) {
                controller.abort();
                response = await handleGetWithPost({
                    url: `report/call_details/${chart}`, data: { ...reportData, startDate: moment().format('YYYY-M-DD'), endDate: moment().format('YYYY-MM-DD') }, signal,
                });
                dispatch(handleReportData({ ...reportData, startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }));
            } else {
                response = await handleGetWithPost({ url: `report/call_details/${chart}`, data: reportData, signal });
            }

            if (response?.data?.data?.length > 0) {
                setChartData(response.data.data);
            } else {
                setChartData([]);
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                // Handle abort error if needed
                console.log('API request aborted');
            } else {
                // Handle other errors
                console.error('API request failed:', error);
            }
        }
    };

    const handleChart = async (chart) => {
        barChartApi(chart);
    };

    const donutChartApi = async () => {
        const response = await handleGetWithPost({ url: 'report/call_details/chart_count', data: reportData });
        if (response?.data?.status === 200) {
            setPieChartData(response.data.data);
        } else {
            setPieChartData({});
        }
    };

    useEffect(() => {
        const today = moment().format('YYYY-MM-DD');
        if (reportData?.startDate && reportData?.endDate) {
            if (activeTabRef.current !== 1) {
                onTabChange(2);
                barChartApi();
                donutChartApi();
            }
            const isConditionMet = reportData?.startDate !== today && reportData?.endDate !== today;
            if (isConditionMet) {
                onTabChange(2);
                barChartApi();
                donutChartApi();
            }
        }
    }, [reportData]);
    // charts options
    const colorPalette = ['#00B051', '#FFB82D', '#FF344C'];
    const revenewOption = {
        title: {
            text: 'CALL DETAIL',
            left: 'center',
            top: '0',
            textStyle: {
                fontSize: 20,
                color: '#000000',
                fontWeight: 700,
            },
        },
        tooltip: {
            formatter: '{b} : {d}%',
            show: true,
        },
        legend: {
            show: true,
            bottom: '10',
            left: 'center',
            itemGap: 18,
            padding: [0, 20, 0, 20],
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                color: colorPalette,
                avoidLabelOverlap: true,
                itemStyle: {
                    // Adjust border color here, set to 'transparent' or a dark mode appropriate color
                    borderColor: 'transparent',
                    borderWidth: 0, // You can adjust the border width as needed
                },
                label: {
                    position: 'center',
                    show: true,
                    // Update the formatter to change text color based on mode
                    formatter(params) {
                        // Assuming dark mode, set the color to black or any dark mode appropriate color
                        // You could dynamically change this color based on the current theme/mode
                        // const textColor = 'black'; // Example, change this based on actual dark mode state
                        return `{a|${params.percent}%}\n{b|${params.name}}`;
                    },
                    // Adjust font size, weight, and now adding color
                    alignTo: 'none', // Disable label alignment to prevent overlap
                    bleedMargin: 5, // Adjust the margin to prevent overlap
                    color: '#ACACAC', // Ensure text color is black for dark mode, adjust as needed
                    rich: {
                        a: {
                            color: '#000000', // Text color for the name
                            lineHeight: 36, // Line height for spacing, adjust as needed
                            fontSize: 26,
                            fontWeight: 'bold',
                        },
                        b: {
                            color: '#000000', // Text color for the percentage
                            lineHeight: 10, // Line height for spacing, adjust as needed
                            fontSize: 14,
                            fontWeight: 'bold',
                        },
                    },
                },
                emphasis: {
                    disabled: true,
                },
                labelLine: {
                    show: false,
                },
                data: [
                    { value: pieChartData.convertedCalls, name: 'Converted' },
                    { value: pieChartData.notConvertedCalls, name: 'Not Converted' },
                    { value: pieChartData.noanswer, name: 'No Answer' },
                ],
            },
        ],
    };
    const option = {
        title: {
            text: 'Call Count',
            left: 'center',
            top: '0',
            textStyle: {
                fontSize: 18,
                color: '#5F6377',
                fontWeight: 600,
            },
        },
        tooltip: {
            trigger: 'axis',
            formatter: '',
            backgroundColor: '#000000c7',
            textStyle: {
                color: '#fff',
            },
        },
        legend: {
            textStyle: {
                color: '#ACACAC',
            },
            itemGap: 18,
            orient: 'horizontal',
            bottom: '30',
            left: '10',
            type: 'scroll',
            pageButtonItemGap: 5,
            pageTextStyle: '#ACACAC',
            data: [
                {
                    name: 'Converted',
                    icon: 'roundRect',
                },
                {
                    name: 'Not Converted',
                    icon: 'roundRect',
                },
                {
                    name: 'No Answer',
                    icon: 'roundRect',
                },
            ],
        },
        grid: {
            left: '3%',
            right: '4%',
            containLabel: true,
            // Adjust the bottom padding of the grid to create space for the legend
            bottom: '30%',
            // Increase this value to create more space for the legend
        },
        xAxis: {
            boundaryGap: [0, '100%'],
            data: chartData.map((item) => item.date),
            axisTick: {
                alignWithLabel: true,
            },

        },
        yAxis: [
            {
                type: 'value',
                boundaryGap: [0, '100%'],
            },
        ],
        series: [
            {
                name: 'Converted', type: 'bar', data: chartData.map((item) => item.convertedCalls), color: '#00B051',
            },
            {
                name: 'Not Converted', type: 'bar', data: chartData.map((item) => item.notConvertedCalls), color: '#FFB82D',
            },
            {
                name: 'No Answer', type: 'bar', data: chartData.map((item) => item.noanswer), color: '#FF344C',
            },
        ],
    };
    // const showCallCountChart = useCallback(() => {
    //     setCallCountchart(!isCallCountchart);
    // });
    // const showCallDetailChart = useCallback(() => {
    //     setCallDetailchart(!isCallDetailchart);
    // });
    return (
        <div className="row">
            <div className="col-12 mt-3">
                <label className="fancy-radio element-left" htmlFor="callCountChart">
                    <input type="radio" name="chartSelection-radio" id="callCountChart" checked={radioStatus === 1} onClick={() => radioHandler(1)} />
                    <span>
                        <i />
                        Call Count
                    </span>
                </label>
                <label className="fancy-radio element-left" htmlFor="callDetialChart">
                    <input type="radio" name="chartSelection-radio" id="callDetialChart" checked={radioStatus === 2} onClick={() => radioHandler(2)} />
                    <span>
                        <i />
                        Call Detail
                    </span>
                </label>
            </div>
            <div className={`py-0 ${radioStatus === 1 ? 'd-block' : 'd-none'} `}>
                <div className="charts-tab mt-3">
                    {/* <!-- Nav tabs --> */}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li
                            className="nav-item"
                            id="bacicTab-1"
                            role="presentation"
                            onClick={() => {
                                onTabChange(1);
                                handleChart('hourly');
                            }}
                        >
                            <a className="nav-link">H</a>
                        </li>
                        <li
                            className="nav-item active"
                            id="bacicTab-2"
                            role="presentation"
                            onClick={() => {
                                onTabChange(2);
                                handleChart('daily');
                            }}
                        >
                            <a className="nav-link active" data-toggle="tab">
                                D
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            id="bacicTab-3"
                            role="presentation"
                            onClick={() => {
                                onTabChange(3);
                                handleChart('monthly');
                            }}
                        >
                            <a className="nav-link" data-toggle="tab">
                                M
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            id="bacicTab-4"
                            role="presentation"
                            onClick={() => {
                                onTabChange(4);
                                handleChart('yearly');
                            }}
                        >
                            <a className="nav-link" data-toggle="tab">
                                Y
                            </a>
                        </li>
                    </ul>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content border-0 p-0">
                        <div id="bacicTabpan-1" className="tab-pane">
                            <ReactEcharts
                                className="barchart"
                                option={option}
                                style={{ height: '400px', width: '100%' }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                        <div id="bacicTabpan-2" className="tab-pane show active">
                            <ReactEcharts
                                className="barchart"
                                option={option}
                                style={{ height: '400px', width: '100%' }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                        <div id="bacicTabpan-3" className="tab-pane">
                            <ReactEcharts
                                className="barchart"
                                option={option}
                                style={{ height: '400px', width: '100%' }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                        <div id="bacicTabpan-4" className="tab-pane">
                            <ReactEcharts
                                className="barchart"
                                option={option}
                                style={{ height: 400 }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`py-3 ${radioStatus === 2 ? 'd-block' : 'd-none'} `}>
                <ReactEcharts
                    option={revenewOption}
                    opts={{ renderer: 'svg' }}
                    style={{ height: '400px', width: '100%' }}
                />
            </div>
        </div>
    );
};

Reportchart.propTypes = {
    setTimeInterval: PropTypes.func,
};

Reportchart.defaultProps = {
    setTimeInterval: () => {},
};
export default Reportchart;
