import React from 'react';

const PermissionNotFoundPage = () => (
    <div className="text-center permission-denied m-5">
        <div className="divCenter">
            <i className="icon-close" />
            <h1>Permission denied</h1>
        </div>
    </div>

);
export default PermissionNotFoundPage;
