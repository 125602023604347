import { baseAPIService } from '.';

const CommonChangeApi = baseAPIService.injectEndpoints({
    endpoints: (build) => ({
        handleUpdateOrDelete: build.mutation({
            query: ({ url, data, method }) => ({
                url,
                method,
                body: data,
            }),
            invalidatesTags: ['COMMON_GET'],
        }),
    }),
    overrideExisting: true,
});

// eslint-disable-next-line import/prefer-default-export
export const { useHandleUpdateOrDeleteMutation } = CommonChangeApi;
