/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CommonModdal from '../../components/Common/CommonModal';
import fileExport from '../../assets/images/file-export.svg';

const IsMoreDataPopup = ({
    show, handleClose, handleSubmit,

}) => (
    <CommonModdal
        show={show}
        type=""
        classAddon="theme-cyan text-center footer-center"
        size="small"
        heading=""
        openSubmitModal={handleSubmit}
        handleClose={handleClose}
        footerButtons1="More"
        footerButtons2="No"
        isDelete
    >
        <img src={fileExport} className="mb-4" width="60" alt="clock" />
        <p>
            Maximum limit for export is 10,000. There are more records available for export. Please click on more to download more.
        </p>
    </CommonModdal>
);
IsMoreDataPopup.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
};
export default IsMoreDataPopup;
